import React, {useState, useEffect} from "react";
import { NavLink, useLocation } from 'react-router-dom';
import { checkIsClubsDomain } from '../../../utils/generic';
import { filterStaticPagesByInstance } from "../../../utils/filterStaticPagesByInstance";
import MobileMenu from './mobileMenu';
import { Grid, Typography, useMediaQuery, useTheme, Link, Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import "./staticPagesNavBar.scss";

const SliderItem = ({ item }) => (
  <Link component={NavLink} to={`${item.url}`}>
    <Box className={'carousel-menu-item'}>
      <Typography variant="body1" className={'carousel-menu-link'}>
        {item.text}
      </Typography>
    </Box>
  </Link>
);

const StaticPagesNavBar = ({ menuItems, navContainerRef }) => {
  const isClubsDomain = checkIsClubsDomain();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
  const location = useLocation();

  const [swiperRef, setSwiperRef] = useState(null);
  const [isSlim, setIsSlim] = useState(false);
  const [headline, setHeadline] = useState('');
  const [navBackLink, setNavBackLink] = useState('');
  const [navBackText, setNavBackText] = useState('');

  menuItems = filterStaticPagesByInstance(menuItems, isClubsDomain);  

  useEffect(() => {
    menuItems.forEach(item => {
      if (item.url === location.pathname) {
        setHeadline(item.headline);
        setNavBackLink(item.navBackLink);
        setNavBackText(item.navBackText);
      }
    })
  }, [menuItems, location.pathname]);

  // Determine the initial active slide based on the URL
  useEffect(() => {
    if (swiperRef) {
      const activeIndex = menuItems.findIndex(item => item.link === location.pathname);
      if (activeIndex >= 0) {
        swiperRef.slideTo(activeIndex); // Slide to the correct index
      }
    }
  }, [location, swiperRef, menuItems]);

  const handleScroll = () => {
    setIsSlim(window.scrollY > 20);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Grid className={`static-page ${isSlim ? 'slim-header' : ''}`}>
      <div className={`nav-container`} ref={navContainerRef}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <a href={navBackLink} target="_blank" className="nav-link">
            <ArrowBackIcon className="arrow-icon" />
            {isMobile ? 'Back' : navBackText}
          </a>
          <MobileMenu menuItems={menuItems} />
        </Box>

        <Box className={`custom-swiper-wrapper ${menuItems.length < 6 ? 'centered' : ''}`}>
          <Swiper
            className="carousel-menu"
            modules={[Navigation, Pagination]}
            height={80}
            pagination={{ clickable: true }}
            spaceBetween={8}
            onSwiper={(swiper) => setSwiperRef(swiper)}
            navigation={{
              nextEl: '.swiper-button-next-custom',
              prevEl: '.swiper-button-prev-custom',
            }}
            breakpoints={{
              721: {
                slidesPerView: 5,
              },
              1024: {
                slidesPerView: 6,
              },
            }}
          >
            {menuItems.map((item, idx) => (
              <SwiperSlide key={idx} className={location.pathname === item.url ? 'selected' : ''}>
                <SliderItem item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
            <div className="swiper-button-prev-custom">
              <ArrowBackIosIcon />
            </div>
            <div className="swiper-button-next-custom">
              <ArrowForwardIosIcon />
            </div>
        </Box>


        <div className="page-headline-wrapper">
          <div className="page-headline-block">
              <span className="headline">{headline}</span>
              <div class="wavy-line"></div>
          </div>
        </div>
      </div>
    </Grid>
  )
};

export default StaticPagesNavBar;