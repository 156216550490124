import client from "../../../apolloClient";
import { gql } from "apollo-boost";

export function updateUserNextInvitation({ invitationId, invitationToken }) {
  return client.mutate({
    mutation: gql`
    mutation{
      expireUserNextInvitation(invitationId: ${invitationId}, invitationToken: "${invitationToken}")
    }`
  });
}
