import React, { useState } from "react";
import { useLocation, NavLink } from 'react-router-dom';
import { Button, Menu, MenuItem } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withStyles } from '@material-ui/core/styles';
import * as colors from "../../../theme/colors";

const StyledMenu = withStyles({
  list: {
    padding: 0,
  },
})(props => (
  <Menu
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    getContentAnchorEl={null}
    {...props}
  />
));

const StyledMenuItem = withStyles({
  root: {
    fontSize: 16,
    fontWeight: 700,
    height: 40,
    '&.active': {
      background: colors.lightBlue,
    },
    '& .link': {
      color: colors.darkBlue100,
      textDecoration: 'none',
    }
  },
})(MenuItem);

const MobileMenu = ({menuItems}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const location = useLocation();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="mobile-menu">
      <Button
        id="menu-button"
        className="nav-link"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        >
        Other Resources    
        <ExpandMoreIcon className={open ? 'rotate-icon' : ''}/>
      </Button>
      <StyledMenu
        id="basic-menu"
        aria-labelledby="menu-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        >
          {menuItems.map((item, index) => {
            return <StyledMenuItem key={index} onClick={handleClose} className={location.pathname === item.url ? 'active' : ''}>
              <NavLink to={item.url} className="link">{item.text}</NavLink>
            </StyledMenuItem>
          })}
      </StyledMenu>
    </div>
  )
}

export default MobileMenu;