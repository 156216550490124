import React, { useState, useEffect } from "react";
import clsx from 'clsx';
import { connectTo } from "../../utils/generic";

import "./gettingStarted.scss";
import MenuItem from "../../components/custom-menu/menu-item/menuItem";
import ContentItem from "../../components/custom-menu/content-item/contentItem";
import NavBar from "../../components/nav-bar/navBar";
import { Switch, Box, Typography } from "@material-ui/core/";
import { getStaticPages } from '../../actions/global';
import { useTitle } from "../../utils/pageTitles";
import Footer from "../../containers/Footer/footer";

const UK_COUNTRY_ID = 224;
const privacyPage = '/privacy';

const GettingStarted = (props) => {
  const {
    location: { pathname : url },
    user,
    getStaticPages,
    global: { staticPageContent }
  } = props;

  useTitle(!!url && url.length > 1 ? url.toLowerCase().substring(1) : null);

  const [header, setHeader] = useState('');
  const [data, setData] = useState(undefined);
  const [isMenuTop, setIsMenuTop] = useState(false);
  const [checked, setChecked] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (!url) return;

    getStaticPages(url);
  }, [url]);

  useEffect(() => {
    if (!staticPageContent) return;

    const data = staticPageContent.modules[0];
    const { heading, contentPage } = data;
    const header = heading ? heading : contentPage.name;
    
    setHeader(header);
    setData(data);

    if (url === privacyPage) {
      // if user is from UK, show UK privacy policy
      if (user && user.countryId == UK_COUNTRY_ID) {
        setTabIndex(1);
      }
      else {
        // if user selected UK as a country on the sign up
        const countryId = localStorage.getItem("signupCountryId");
        if (parseInt(countryId, 10) === UK_COUNTRY_ID){
          setTabIndex(1);
        }
      }
    }

    window.addEventListener("scroll", handleScroll);
  }, [staticPageContent]);

  const handleScroll = () => {
    let isMenuTop = window.scrollY >= 169;
    setIsMenuTop(isMenuTop);
  };

  const onTabClick = (index) => {
    setTabIndex(index);
  }

  const pageWithoutContent = data && data.contentSections;

  const contentPage = data && data.contentPage ? data.contentPage : null;
  const currentTab = contentPage
    ? contentPage.tabsWithContent[tabIndex]
    : null;

  let contentSection;
  if (currentTab) {
    contentSection = checked
      ? currentTab.childFriendlyContent[0]
      : currentTab.contentTabContent[0];
  }

  let navTabs = contentPage ? contentPage.tabsWithContent.map(e => { return e.tab; }) : null;

  const allowNavigation =
    pageWithoutContent &&
    data.contentSections.find(e => e.visibleInPageNavigation);

  const isPageWithTable = window.location.href.includes("privacy") ||
    window.location.href.includes("cookie-policy");

  return (
    <>
      <NavBar
        title={header}
        tabs={navTabs}
        onClick={onTabClick}
        tabIndex={tabIndex}
        showBack={url} />
      <div className="page">
        <div className={isPageWithTable ? clsx("main-content", "table-content") : "main-content"}>
          {allowNavigation && (
            <div className="sticky-nav">
              <div className={`stuck ${isMenuTop ? "nav-top" : ""} `}>
                {data.contentSections.map((el, index) => {
                  return (
                    <MenuItem
                      key={index}
                      id={el.heading}
                      title={el.heading}
                    ></MenuItem>
                  );
                })}
              </div>
            </div>
          )}
          <div className="content">
            {!pageWithoutContent && (
              <Box className={"switch-container"}>
                <Typography>Child Friendly</Typography>
                <Switch
                  value={checked}
                  color={"primary"}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  onClick={() => setChecked(!checked)}
                />
              </Box>
            )}
            {pageWithoutContent &&
              data.contentSections.map((el, index) => {
                return (
                  <ContentItem
                    key={index}
                    id={el.heading}
                    title={el.heading}
                    content={el.content}
                  ></ContentItem>
                );
              })}
            {contentPage && contentSection && (
              <Box>
                <ContentItem
                  id={contentSection.heading}
                  title={contentSection.heading}
                  content={contentSection.content}
                ></ContentItem>
              </Box>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default connectTo(
  state => ({
    user: state.auth.user,
    global: state.global,
  }),
  {
    getStaticPages
  },
  GettingStarted
);