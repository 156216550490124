import { CompositeDecorator } from 'draft-js';
import CustomLink from './Components/CustomLink';
import { ENTITY_TYPES } from './constants';

const createEntityStrategy = (type) => (contentBlock, callback, contentState) => {
  contentBlock.findEntityRanges(
    (character) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === type;
    },
    callback
  );
};

export default new CompositeDecorator([
  {
    strategy: createEntityStrategy(ENTITY_TYPES.LINK),
    component: CustomLink,
  },
]);
