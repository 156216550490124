import client from "../../../apolloClient";
import { gql } from "apollo-boost";
import { getModulesQuery } from "../types/modules";
import obj2arg from "graphql-obj2arg";

export const getStaticPages = (url) => {
  return client.query({
    query: gql` 
    {
      PageQuery(
        slug: "${url}"
      ) {
        id
        slug
        layout
        modules {
          id
          type
          ${getModulesQuery()}
        }
      } 
    }`
  });
};

export const getStaticPageAttributes = () => {
  return `
    downloadPdfButtonLink
    lastUpdated
    introductoryText
    contentSections {
      ...on ContentSection {
        heading
        subheading
        content
        visibleInPageNavigation
        subContentSection
      }
    }
    nestedSection {
      cardIcon {
        alt
        name
        src
      }
      cardText
      groupedContentSections {
        headingName
        contentSections {
          ... on ContentSection {
            heading
            subheading
            content
            visibleInPageNavigation
            subContentSection
          }
        }
      }
    }
  `;
};

export const getStaticPageAttributesForNavigation = () => {
  return `
    navbarCommonLink
    navbarCommonText
    name
    title
    url
    instance
  `;
}

export function getStaticPageByUrl({url}) {
  return client.query({
    query: gql`
      {
        GetStaticPageByUrl(${obj2arg({url}, { noOuterBraces: true })}) {
          ${getStaticPageAttributes()}
        }
      }
    `
  });
}


export function getAllStaticPages() {
  return client.query({
    query: gql`
      {
        GetAllStaticPages {${getStaticPageAttributesForNavigation()}}
      }
    `
  });
}