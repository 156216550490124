import React, { useState, useEffect, useRef } from "react";
import { useLocation } from 'react-router-dom';
import { connectTo, createMarkup, checkIsClubsDomain } from "../../utils/generic";
import { useTitle } from "../../utils/pageTitles";
import { getStaticPagesNav, getStaticPageByUrl } from '../../actions/global';
import * as colors from "../../theme/colors";
import { filterStaticPagesByInstance } from "../../utils/filterStaticPagesByInstance";

import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Box, Drawer } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import ArrowSliderIcon from '@material-ui/icons/KeyboardBackspace';

import "../getting-started/gettingStarted.scss";
import "../static/staticPages.scss";
import MenuItem from "../../components/custom-menu/menu-item/menuItem";
import ContentItem from "../../components/custom-menu/content-item/contentItem";
import CustomButton from '../../components/customButton/customButton';
import StaticPagesNavBar from "./staticPagesNavBar/staticPagesNavBar";
import Footer from "../../containers/Footer/footer";
import downloadIcon from '../../images/download-icon.svg';
import openDrawerMenuIcon from '../../images/open-drawer-menu-icon.svg';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

const StaticPages = (props) => {
  const {
    location: { pathname : url },
    getStaticPagesNav,
    getStaticPageByUrl,
    global: { staticPagesNav, separateStaticPageContent }
  } = props;

  const useStyles = makeStyles(() => ({
    closeBtn: {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: '5px 10px',
      position: 'sticky',
      top: 10,
      color: colors.accessibleTeal3,
    },
  }));

  const classes = useStyles();
  const location = useLocation();
  const isClubsDomain = checkIsClubsDomain();

  // offsets needed for 'react-scroll' package to scroll to the correct position
  const desktopOffset = -300; //scrolls to element -300 px down the page
  const mobileOffset = -250; //scrolls to element -250 px down the page

  const StyledDrawer = withStyles({
    paper: {
      width: '90%',
      position: 'absolute',
      margin: '50px auto',
      boxSizing: 'border-box',
      maxHeight: '80vh',
      overflowY: 'auto',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  })(Drawer);

  useTitle(!!url && url.length > 1 ? url : 'static-pages');

  const [data, setData] = useState(null);
  const [isMenuTop, setIsMenuTop] = useState(false);
  const [isNavigationAllow, setAllowNavigation] = useState(false);
  const [menuItems, setMenuItems] = useState([]);
  const [open, setOpen] = useState(false);
  const [currentNestedSectionCardIndex, setCurrentNestedSectionCardIndex] = useState(0);
  const navContainerRef = useRef(null);

  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  useEffect(() => {
    getStaticPagesNav();

    if (!staticPagesNav) return;

    let menuItems = staticPagesNav.map((item) => {
      return {
        text: item.name, 
        url: item.url, 
        headline: item.title, 
        navBackText: item.navbarCommonText,
        navBackLink: item.navbarCommonLink,
        instance: item.instance,
      };
    });

    menuItems = filterStaticPagesByInstance(menuItems, isClubsDomain);

    setMenuItems(menuItems);

    const includesUrl = menuItems.some(item => item.url === location.pathname);

    if (!includesUrl) {
      window.location.href = '/home';
    }
  }, []);

  useEffect(() => {
    if (!url) return;

    getStaticPageByUrl({url});
  }, [url]);

  useEffect(() => {
    if (!separateStaticPageContent) return;

    setData(separateStaticPageContent);

    if (separateStaticPageContent.contentSections) {
      setAllowNavigation(!!separateStaticPageContent.contentSections.find(e => e.visibleInPageNavigation));
    } else if (separateStaticPageContent.nestedSection) {
      const hasVisibleSideNavigation = separateStaticPageContent.nestedSection[currentNestedSectionCardIndex].groupedContentSections.some(section => {
        return section.contentSections.some(contentSection => contentSection.visibleInPageNavigation)
      });
      setAllowNavigation(hasVisibleSideNavigation);
    } else {
      setAllowNavigation(false);
    }

    window.addEventListener("scroll", handleScroll);

  }, [separateStaticPageContent, currentNestedSectionCardIndex]);

  const handleScroll = () => {
    let isMenuTop = window.scrollY >= navContainerRef.current.clientHeight;
    setIsMenuTop(isMenuTop);
  };

  const pageWithContentSections = data && data.contentSections;
  const pageWithNestedSections = data && data.nestedSection;

  useEffect(() => {
    setCurrentNestedSectionCardIndex(0);
  }, [location.pathname]);

  const renderNestedSectionsSidebar = (offset, clickHandlerFn) => {
    return (
      pageWithNestedSections[currentNestedSectionCardIndex].groupedContentSections.map((el, index) => (
        <Box key={index}>
          {el.contentSections && el.contentSections.length > 0 && (
            <MenuItem
              key={index}
              id={el.contentSections[0].heading}
              title={el.headingName}
              offset={offset}
              onClick={clickHandlerFn}
            />
          )}
          {el.contentSections && el.contentSections.map((contentSection, contentIndex) => (
            contentSection.visibleInPageNavigation && (
              <MenuItem
                key={contentIndex}
                id={contentSection.heading}
                title={contentSection.heading}
                subContent={contentSection.subContentSection}
                offset={offset}
                onClick={clickHandlerFn}
              />
            )
          ))}
        </Box>
      ))
    );
  }

  return (
    <>
      <StaticPagesNavBar menuItems={menuItems} navContainerRef={navContainerRef} />

      <div className="page static-page">
        <div className="main-content">
          {/* desktop sidebar navigation */}
          {isNavigationAllow && (
            <div className="sticky-nav">
              <div className={`stuck-sidebar ${isMenuTop ? "nav-top" : ""} `}>
                {data.downloadPdfButtonLink && (
                  <CustomButton
                  mode="secondary"
                  className="button-download"
                  onClick={() => window.open(`${data.downloadPdfButtonLink}`, '_blank')}
                >
                  <img src={downloadIcon} className="icon-download" alt="download" />
                  Download PDF
                </CustomButton>
                )}


              {pageWithNestedSections ? (
                renderNestedSectionsSidebar(desktopOffset)
              ) : (
                data.contentSections.map((el, index) => (
                  el.visibleInPageNavigation && (
                    <MenuItem
                      key={index}
                      id={el.heading}
                      title={el.heading}
                      subContent={el.subContentSection}
                      offset={desktopOffset}
                    />
                  )
                ))
              )}
              </div>
            </div>
          )}

          {/* mobile sidebar navigation */}
          {isNavigationAllow && (
            <Box>
              <Box className="mobile-nav-btn" onClick={toggleDrawer(true)}>
                <img src={openDrawerMenuIcon} alt="open side menu icon" />
              </Box>

              <StyledDrawer open={open} onClose={toggleDrawer(false)} anchor="left">
                <IconButton
                  size="medium"
                  color="inherit"
                  className={classes.closeBtn}
                  onClick={toggleDrawer(false)}
                  aria-label="close"
                >
                  <CloseIcon fontSize="medium" />
                </IconButton>

                {pageWithNestedSections ? (
                  renderNestedSectionsSidebar(mobileOffset, toggleDrawer(false))
                ) : (
                  data.contentSections.map((el, index) => (
                    el.visibleInPageNavigation && (
                      <MenuItem
                        key={index}
                        id={el.heading}
                        title={el.heading}
                        subContent={el.subContentSection}
                        offset={mobileOffset}
                        onClick={() => toggleDrawer(false)()}
                      />
                    )
                  ))
                )}
              </StyledDrawer>
            </Box>
          )}
   
          <div className="content">
            {data && data.lastUpdated && (
              <Box className="last-updated">Last updated: {data.lastUpdated}</Box>
            )}
            
            {/* Optional introductory content block */}
            {data && data.introductoryText && (
              <div className="rich-text" dangerouslySetInnerHTML={createMarkup(data.introductoryText)}></div>
            )}
            
            {pageWithNestedSections ? (
              <>
                <Box className="nested-swiper-block">
                  <Box className="arrows">
                    <Box className="nav-arrow arrow-prev">
                      <ArrowSliderIcon />
                    </Box>
                    <Box className="nav-arrow arrow-next">
                      <ArrowSliderIcon />
                    </Box>
                  </Box>
                  <Swiper
                    className="nested-swiper"
                    spaceBetween={7}
                    height={250}
                    modules={[Navigation]}
                    navigation={{
                      nextEl: '.arrow-next',
                      prevEl: '.arrow-prev',
                    }}
                    breakpoints={{
                      375: {
                        slidesPerView: 2.5,
                      },
                      550: {
                        slidesPerView: 3.5,
                      },
                      720: {
                        slidesPerView: 4.7,
                      },
                      1024: {
                        slidesPerView: 4,
                      },
                      1300: {
                        slidesPerView: 5,
                      },
                    }}
                  >
                  {data.nestedSection.map((el, index) => (
                    <SwiperSlide key={index}>
                      <Box 
                        className={`nested-card ${currentNestedSectionCardIndex === index ? 'active' : ''}`} 
                        onClick={() => setCurrentNestedSectionCardIndex(index)}
                      >
                        <img src={el.cardIcon.src} alt={el.cardIcon.alt} />
                        <Box className="nested-role">{el.cardText}</Box>
                      </Box>
                    </SwiperSlide>
                  ))}
                  </Swiper>
                </Box>

                {pageWithNestedSections &&
                  pageWithNestedSections[currentNestedSectionCardIndex].groupedContentSections.map((el, index) => (
                    <Box key={index} className="content-item">
                      <Box className="title-category">{el.headingName}</Box>
                      {el.contentSections.map((contentSection, contentIndex) => (
                        <ContentItem
                          key={contentIndex}
                          id={contentSection.heading}
                          title={contentSection.heading}
                          content={contentSection.content}
                          subContent={contentSection.subContentSection}
                          isFirst={contentIndex === 0}
                        />
                      ))}
                    </Box>
                  ))}
              </>
            ) : (
              pageWithContentSections && 
              data.contentSections.map((el, index) => (
                <ContentItem
                  key={index}
                  id={el.heading}
                  title={el.heading}
                  content={el.content}
                  subContent={el.subContentSection}
                />
              ))
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}

export default connectTo(
  state => ({
    global: state.global,
  }),
  {
    getStaticPagesNav, 
    getStaticPageByUrl,
  },
  StaticPages
);
