import { useRef } from "react";
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as moment from "moment";
import { alphabet, numbersList } from './lettersAndNumbers.dict';
import { get } from 'lodash';
import { SCHOOL_CLUBS, DATE_FORMAT } from './constants';

export const connectTo = (mapStateToProps, actions, Component) => {
  const mapDispatchToProps = dispatch => bindActionCreators(actions, dispatch)
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Component);
}

export const filterField = (list, key, keyValue) => {
  if (list == null) return null;
  let filteredField = list.find((obj) => obj[key] === keyValue);
  if (filteredField == null) return null;
  return filteredField;
}

export const filterFieldWithReturnKey = (list, key, keyValue, returnKey) => {
  let filteredField = filterField(list, key, keyValue);
  if (filteredField == null) return "";
  return filteredField[returnKey];
}

export const filterFieldsWithKey = (list, key) => {
  return list.reduce((acc, message) => ({
    ...acc,
    [message[key]]: message,
  }), {});
}

export const filterFieldsByKey = (list, key, returnKey) => {
  return list.reduce((acc, message) => ({
    ...acc,
    [message[key]]: message[returnKey],
  }), {});
}

export const createMarkup = (htmlString) => {
  return { __html: htmlString || ""} ;
}

export const urlify = (text) => {
  var urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.replace(urlRegex, function(url) {
    return `<a target="_blank" href="${url}">${url}</a>`;
  })
}

export const windowOpen = (url, isNewWindow = true) => {
  if (!url.match(/^https?:\/\//i)) {
      url = 'http://' + url;
  }
  return window.open(url, isNewWindow ? "_blank" : "");
}

export const validatePasswordRules = (value, email, username, firstName) => {
  let result = [];
  const passwordValue = !!value.length ? value.toLowerCase() : null;

  //must be 10 chars minimum
  if (value.length < 10) {
    result["minValue"] = true;
  }

  //differ from firstName, username or email;
  if (!!passwordValue &&
    ((firstName && passwordValue.includes(firstName.toLowerCase()))
    || (username && passwordValue.includes(username.toLowerCase()))
    || (email && passwordValue.includes(email.toLowerCase())
    ))) {
    result["differentThanUserData"] = true;
  }

  const isSequenceAlphabet = checkSequencePassword(passwordValue, alphabet);
  const isSequenceNumbers = checkSequencePassword(passwordValue, numbersList);
  const isRepetitiveSymbols = verifyRepetitiveSymbols(passwordValue);

  if (isSequenceAlphabet || isSequenceNumbers || isRepetitiveSymbols) {
    result["isCommonPassword"] = true;
  }

  return result;
}

const checkSequencePassword = (passwordValue, array) => {
  if (!passwordValue) {
    return false;
  }

  const charsArray = !!passwordValue ? Array.from(passwordValue.toUpperCase()) : [];

  let firstIndex = array.indexOf(charsArray[0]);
  let secondIndex = array.indexOf(charsArray[1]);
  
  if (firstIndex === -1 || secondIndex === -1) {
    return false;
  }

  let isReverse = (firstIndex === 0 && secondIndex === array.length - 1) || firstIndex === (secondIndex + 1);

  for (let i = 0; i < charsArray.length; i++) {
    let originalIndex = 0;
    
    if (isReverse) {
      const diff = firstIndex - i;
      originalIndex = diff < 0 ? array.length - (Math.abs(diff) % array.length) : diff;
    } else {
      const totalIndex = i + firstIndex;
      originalIndex = totalIndex > array.length -1 ? totalIndex % array.length : totalIndex;
    };

    if (originalIndex === array.length) {
      // move index to start of array;
      originalIndex = 0;
    }

    const isMatch = charsArray[i] === array[originalIndex];
    if (!isMatch) {
      return false;
    }
  }

  return true;
}

const verifyRepetitiveSymbols = (passwordValue) => {
  if (!passwordValue) {
    return false;
  }
  const charsArray = Array.from(passwordValue.trim().toUpperCase());
  const firstItem = charsArray[0]; 
  return charsArray.filter(e => e === firstItem).length === charsArray.length;
};

export const alphanumericRegex = /^[a-zA-Z0-9]*$/;

export const validateUserNameRules = (value, user) => {
  if (value.length < 7){
    return 'Username must be at least 7 characters long';
  }

  //must have at least one number
  if (!/\d{1}/i.test(value.trim())){
    return 'Username must contain at least one number';
  }

  //must have at least one letter
  if (!/[a-zA-Z]+/i.test(value.trim())){
    return 'Username must contain at least one letter';
  }

  // must not contain first name
  if (user.firstName && user.firstName.length > 0 &&
    value.trim().toLowerCase().indexOf(user.firstName.trim().toLowerCase()) !== -1) {
    return 'Username must not contain your name';
  }

  // must not contain last name
  if (user.lastName && user.lastName.length > 0 &&
    value.trim().toLowerCase().indexOf(user.lastName.trim().toLowerCase()) !== -1) {
    return 'Username must not contain your name';
  }

  if (!alphanumericRegex.test(value)) {
    return `Username shouldn't contain special characters`;
  }
};

export const validateBirthDate = (value) => {
  const minDate = moment().subtract(100, 'years').format(DATE_FORMAT);
  const birthDateFormatted = moment(value).format(DATE_FORMAT);
  const currentDate = moment().startOf('date');
  const isFutureDate = moment(birthDateFormatted).startOf('date').isAfter(currentDate);
  
  if (isFutureDate) {
    return "Date of Birth cannot be the future date";
  }
  
  if (!value || value === '' || birthDateFormatted < minDate) {
    return "Please check the format of your date of birth";
  }
}

export const formatEstimatedMinutes = minutes => {
  let hours = 0;
  let minutesRest = parseInt(minutes, 10);

  while (minutesRest >= 60) {
    minutesRest -= 60;
    hours += 1;
  }

  return [
    hours === 0 ? '' : `${hours}hr`,
    minutesRest === 0 ? '' : `${minutesRest}min`,
  ].join(' ').trim();
}

export const sortLastThenFirst = (data) => {
  return data
    ? data.sort((a, b) => {
        const aFirstName = a.firstName ? a.firstName.toLowerCase() : "";
        const bFirstName = b.firstName ? b.firstName.toLowerCase() : "";
        const aLastName = a.lastName ? a.lastName.toLowerCase() : "";
        const bLastName = b.lastName ? b.lastName.toLowerCase() : "";

        const aa = `${aFirstName} ${aLastName}`;
        const bb = `${bFirstName} ${bLastName}`;

        if (aa > bb)
          return 1;
        else if (aa < bb)
          return -1;
        return 0;
      })
    : [];
}

export const arrayToObject = (array, key, secondKey) => {
  return array.reduce((collectionObject, item) => {
    const itemKey = ((secondKey ? `${get(item, key)} ${get(item,secondKey, '')}` : item[key]) || "").toString();
    collectionObject[itemKey] = collectionObject[itemKey] || [];
    collectionObject[itemKey].push(item);
    return collectionObject;
  }, {});
};

export const arrayToObjectNotForcedArrayValues = (array, key) => {
  return array.reduce((collectionObject, item) => {
    const itemKey = (item[key] || '').toString();
    collectionObject[itemKey] = item;
    return collectionObject;
  }, {});
};

export const getUserName = (user) => {
  const {firstName , lastName} = user;
  return `${firstName}${lastName ? ' ' : ''}${lastName ? lastName : ''}`;
}

export const isValidEmail = (email) => {
  const regexp = /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
  return !regexp.test(email?.toLowerCase());
}

export const days = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const useFocus = () => {
  const htmlElRef = useRef(null);
  const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()};

  return [ htmlElRef, setFocus ] ;
};

export const getRestNames = (members, maxMembersToDisplay) => {
  const restNamesList = []

  members.forEach((member, item) => {
    if (item >= maxMembersToDisplay) {
      restNamesList.push(getUserName(member))
    }
  });

  return restNamesList.join('\n');
}

export const friendlyElapsedTime = date => {
  const newDate = moment(date);

  return newDate.format("MM/DD/YYYY h:mm A");
};

export const getDateLabel = (date) => {
  let dt = new Date(date);
  let currentDate = new Date();
  let diffDays = currentDate.getDate() - dt.getDate();
  let diffMonths = currentDate.getMonth() - dt.getMonth();
  let diffYears = currentDate.getFullYear() - dt.getFullYear();

  const formattedHours = dt.getHours() > 9 ? dt.getHours() : `0${dt.getHours()}`;
  const formattedMinutes = dt.getMinutes() > 9 ? dt.getMinutes() : `0${dt.getMinutes()}`;

  if (diffYears === 0 && diffDays === 0 && diffMonths === 0) {
    return `Today ${formattedHours}:${formattedMinutes}`;
  } else if (diffYears === 0 && diffMonths === 0 && diffDays === 1) {
    return `Yesterday ${formattedHours}:${formattedMinutes}`;
  } else {
    return moment(date).format("DD MMM YYYY [at] HH:mm");
  }
};

export const checkIsClubsDomain = () => {
  return window.location.host.split('.').some((v) => v.includes('clubs'));
}

const schoolClubs = Object.values(SCHOOL_CLUBS);
export const isSchoolClub = (clubType) => {
  if (!clubType) return false;

  return schoolClubs.some(item => item.toLowerCase() === clubType.toLowerCase());
}

export const mapOrder = (array, orderArray, key) => {
  const order = orderArray.reduce((r, k, i) => (r[k] = i + 1, r), {});
  return array.sort((a, b) => (order[a[key]] || Infinity) - (order[b[key]] || Infinity));
}

export const getAcademicYear = (year) => {
  return `${year}-${parseInt(year.toString().substr(2)) + 1}`;
}

export const getCountryFormat = (selectedCountry, domesticId) => {
  return !selectedCountry || +selectedCountry === +domesticId
          ? "MM/dd/yyyy"
          : "dd/MM/yyyy";
}

export const decodeHTML = (htmlString) => {
  var txt = document.createElement('textarea');
  txt.innerHTML = htmlString;
  return txt.value;
};

export const targetBlankForLinks = (text) => {
  var urlRegex = /(<a)/g;
  return text.replace(urlRegex, function() {
    return `<a target="_blank"`;
  });
};

export const getCurriculumDisplayName = (name) =>  name.substring(0, 32);

export const getProgramDisplayName = (name) =>  name.replace(new RegExp('_', 'g'), ' ');
