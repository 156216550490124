import cta from './Cta';
import image from './Image';

const ProfileMessaging = `
  name
  personalInfoIcon {
    ${image}
  }
  personalAddressInfoIcon {
    ${image}
  }
  publicProfileIcon {
    ${image}
  }
  personalInfoLabel
  parentsIcon {
    ${image}
  }
  securityIcon {
    ${image}
  }
  securityLabel
  securityCta {
    ${cta}
  }
  myProgramsIcon {
    ${image}
  }
  myProgramsLabel
  myProgramsCta {
    ${cta}
  }
  myCollegeLoopsCta {
    ${cta}
  }
  facilitatorMyProgramsInstruction
  facilitatorMyProgramsCta {
    ${cta}
  }
  pastProgramsIcon {
    ${image}
  }
  pastProgramsLabel
  facilitatorGirlsPasswordIcon {
    ${image}
  }
  facilitatorGirlsPasswordLabel
  facilitatorGirlsPasswordInstruction
  facilitatorGirlsPasswordCta {
    ${cta}
  }
  communityJoinOrganizationIcon {
    ${image}
  }
  communityJoinOrganizationLabel
  communityJoinOrganizationInstruction
  communityJoinOrganizationCta {
    ${cta}
  }
  notificationsIcon {
    ${image}
  }
`;

export default ProfileMessaging;
