import React, { useMemo } from "react";

import {
  Box,
  Card,
  Typography,
} from "@material-ui/core/";

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "../../../theme/colors";
import TextCustomButton from "../../../components/text-button/text-button";
import CheckCircle from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { ColorTheme } from '../../../utils/colorTheme';
import { TASK_STATUSES } from '../../../utils/constants';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import GradeIcon from '../../../images/grade.svg?react';

const useStyles = makeStyles((theme) => ({
  headerBox: {
    padding: 16,
    display: "flex",
    marginBottom: 15,
    alignItems: "center",
    width: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  titleBox: {
    display: "flex",
  },
  backBtnBox: {
    display: 'flex',
    justifyContent: 'end',
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'left',
    },
  },
  backBtn: {
    display: "flex",
  },
  status: {
    display: "flex",
  },
  chatBubbleIcon: {
    marginLeft: 10,
  },
  contentHeader: {
    color: colors.darkThemeBlue,
    fontSize: 18,
    fontWeight: "bold",
    paddingRight: 10,
  },
  assignmentIconBox: {
    marginRight: 10,
  },
  checkmarkIcon: {
    color: colors.lightThemeGreen,
    height: 22,
    marginLeft: 5,
  },
  gradeIcon: {
    width: 20,
    height: 20,
  },
  warningIcon: {
    color: ColorTheme.orange3.b,
    height: 22,
    marginLeft: 5,
  },
  checkmarkIcon: {
    color: ColorTheme.teal2.b,
    height: 22,
    marginLeft: 5,
  },
}));


export const AnswerCardHeader = ({ requiredGrading, heading, onBackClick, answerGroup }) => {
  const classes = useStyles();

  const pendingGrade = answerGroup?.status === TASK_STATUSES.review && requiredGrading;

  const answerLabel = useMemo(() => {
    if (!answerGroup && !requiredGrading) return "Complete";
    if (pendingGrade) return "Submitted | Pending Grade";

    switch (answerGroup.status) {
      case TASK_STATUSES.complete: return "Complete";
      case TASK_STATUSES.incomplete: return "Incomplete";
      default: return "Submitted";
    }
  }, [answerGroup?.status, pendingGrade])

  return (
    <Card className={classes.headerBox}>
      <Box className={classes.titleBox}>
        <Box className={classes.assignmentIconBox}>
          {requiredGrading ? <GradeIcon className={classes.gradeIcon} /> : <AssignmentOutlinedIcon />}
        </Box>
        <Typography className={classes.contentHeader}>{heading}</Typography>
      </Box>
      <Box className={classes.status}>
        <Typography>{answerLabel}</Typography>
        {answerLabel === "Complete" && <CheckCircle className={classes.checkmarkIcon} />}
        {answerLabel === "Incomplete" && <WarningIcon className={classes.warningIcon} />}
      </Box><Box/>
      <Box className={classes.backBtnBox}>
        <TextCustomButton
          label={
            <Box className={classes.backBtn}>
              <ArrowBackIcon />
              <Box>Back to all answers</Box>
            </Box>
          }
          onClick={() => onBackClick()}
        />
      </Box>
    </Card>
  )
}
