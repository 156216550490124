import React from "react";

import { makeStyles } from "@material-ui/core/styles";
import clsx from "clsx";

import Button from "@material-ui/core/Button";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowRigthIcon from "@material-ui/icons/KeyboardArrowRight";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import Grid from "@material-ui/core/Grid";

import { RatingIconProgress } from "./ratingIconProgress";
import ProgressCircleBlue from "../../Progress/ProgressCircle/progressCircleBlue";
import { handleKeyPress } from '../../../utils/accessibility';
import { Box } from "@material-ui/core";
import * as colors from "../../../theme/colors";
import { formatEstimatedMinutes } from "../../../utils/generic";
import ConfirmDialog from "../../../components/ConfirmDialog";
import CheckCircle from "@material-ui/icons/CheckCircle";
import SkipNextOutlinedIcon from '@material-ui/icons/SkipNextOutlined';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import GradeIcon from '../../../images/grade.svg?react';

const useStyles = makeStyles((theme) => ({
  table: {
    // maxWidth: '700px',
  },
  boldCell: {
    fontWeight: "bold",
  },
  regularCell: {
    color: colors.grayTheme,
    padding: '0px 10px 0px 10px',
    height: 55,
    fontSize: 14,
    whiteSpace: "nowrap",
    textAlign: 'left',
  },
  titleSetCell: {
    height: 95,
    textAlign: 'left',
    color: colors.darkBlue100,
    width: '70%',
    [theme.breakpoints.down("md")]: {
      whiteSpace: "nowrap",
      width: 650,
    },
  },
  cellTitle: {
    fontSize: 18,
    fontWeight: 700,
    color: colors.grayTheme,
    margin: 0,
  },
  cellText: {
    color: '#7A7A78',
    fontSize: 14,
    margin: 0,
    marginTop: -15,
  },
  contentSectionsHeader: {
    whiteSpace: "nowrap",
    backgroundColor: colors.greyLight2,
    // fontSize: "1.1rem",
    fontWeight: "bold",
    color: colors.grayTheme,
    // lineHeight: "2.5rem",
    // width: "20%",
    padding: 5,
  },
  regularProgressButtonBox: {
    minWidth: 150,
    minHeight: 40,
    display: "flex",
    alignItems: "left",
  },
  firstColumn: {
   width: 75,
  },
  secondColumnActivity: {
    textAlign: 'left',
    paddingLeft: 150,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 50,
    },
  },
  buttonWrapper: {
    textAlign: 'right',
    paddingRight: 25,
    whiteSpace: "nowrap",
    color: colors.teal2,
    width: '25%'
  },
  checkmarkIcon: {
    color: colors.darkThemeGreen,
  },
  row: {
    "&:hover": {
      backgroundColor: "#E5F8FF !important"
    }
  },
  setTitle: {
    paddingLeft: 100,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 20,
    },
  },
  setActivityTitle: {
    paddingLeft: 130,
    [theme.breakpoints.down("md")]: {
      paddingLeft: 30,
    },
  },
  checkCircle: {
    height: 55,
    width: 55,
    color: colors.blue2,
  },
  goToButton: {
    fontWeight: 700,
    fontSize: 16,
    color: colors.teal2,
  },
  cell: {
    width: "15%",
    [theme.breakpoints.down("md")]: {
      whiteSpace: "nowrap",
      minWidth: 120,
    },
  },
  regularProgressButton: {
    fontWeight: "bold",
    fontSize: "16px",
    paddingRight: "50px",
  },
  titleCell: {
    width: '50%',
    maxWidth: 350,
    minWidth: 350,
    [theme.breakpoints.down("md")]: {
      overflow: 'hidden',
      whiteSpace: "wrap",
      textOverflow: 'ellipsis',
    },
  },
  completedWrapper: {
    marginRight: 5,
  },
  gradeIcon: {
    width: 20,
    height: 20,
    fill: colors.blue3,
  },
  certificateIcon: {
    height: 25,
    position: 'absolute',
    bottom: '-8px',
    left: 30,
    zIndex: 2,
  },
  certificateIconActivityCompleted: {
    bottom: 2,
    left: 33,
  },
  iconWrapper: {
    position: 'relative',
    width: 'max-content',
  },
}));

const SetProgress = ({ percent }) => {
  return (
    <div>
      <ProgressCircleBlue value={percent} />
    </div>
  );
};

const ProgressButton = ({
  completed,
  notStarted,
  onClick,
  shouldFlip,
  hover,
  setHover,
}) => {
  const classes = useStyles();

  let btnText = "RESUME";
  let color = colors.teal3;
  let icon = <SkipNextOutlinedIcon/>
  if (completed) {
    btnText = "REDO";
    icon = <AutorenewIcon/>
    color = "#F37C6F";
  } else if (!completed && notStarted) {
    btnText = "START";
    icon = <PlayArrowIcon/>
    color = colors.blue2;
  }

  return (
    <Box className={classes.regularProgressButtonBox}>
      {hover && shouldFlip ? (
        <Button
          className={classes.regularProgressButton}
          style={{ color }}
          onClick={onClick}
          startIcon={icon}
          tabIndex={0}
          onBlur={() => setHover(false)}
          onKeyPress={handleKeyPress(() => onClick())}
        >
          {btnText}
        </Button>
      ) : <div></div>}
    </Box>
  );
};

const ActivityRow = ({
  aRow,
  set,
  classes,
  onPush,
  isMyProgress,
  clearProgress,
  showContentSection,
  ratings,
  setActivity,
  userId,
}) => {
  const [showConfirmation, setShowConfirmation] = React.useState(null);
  const [hover, setHover] = React.useState(false);

  if (!aRow.logs.lastActivityLogDate && showContentSection) {
    return null;
  }

  // added to prevent an error if content sections dont exist or were removed from contentfull,
  // but logs for this activity exist in db.
  let contentSectionsCount = 0;
  if (aRow.contentSections) {
    contentSectionsCount = isMyProgress
      ? aRow.contentSections.length
      : aRow.contentSections.filter((r) => !r.onlyVisibleToFacilitators).length;
  }

  const isDone = !aRow.isIncomplete && aRow.logs.isDone;
  let progress = isDone
    ? `${contentSectionsCount} of ${contentSectionsCount}`
    : `${aRow.logs.contentSections.filter(section => !section.isIncomplete).length} of ${contentSectionsCount}`;

  let timeOrDate = "";
  const d = aRow.logs.lastActivityLogDate
    ? new Date(aRow.logs.lastActivityLogDate)
    : new Date();
  const month = d.getMonth() > 8 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
  const date = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;
  const latestActivityLogDate = `${month}/${date}/${d.getFullYear()}`;
  timeOrDate = latestActivityLogDate;

  const isLatestActivity =
    set && set.latestActivity && set.latestActivity.id === aRow.id;

  let rate = null;
  if (ratings)
    rate = ratings.find(
      (r) =>
        userId === r.userId &&
        r.activity === aRow.slug &&
        ((set && r.set === set.slug) ||
          (r.collection && aRow.path == `/${r.collection}/${r.activity}`))
    );

  let time = '';
  const minutes = Math.floor(aRow.logs.timeSpent / 60);
  if (minutes > 60) {
    time = formatEstimatedMinutes(minutes);
  } else {
    time = minutes > 0 ? `${minutes} mins` : "< 1 min";
  }

  return (
    <React.Fragment>
      <ConfirmDialog
        content="Are you sure you want to redo this item? Your progress will be cleared."
        onCancel={() => {
          setShowConfirmation(null);
        }}
        onConfirm={() => {
          clearProgress(showConfirmation);
          setShowConfirmation(null);
        }}
        open={showConfirmation}
        title="Clear progress"
        okTitle="Agree"
      />

      <TableRow 
        key={aRow.id} 
        className={classes.row} 
        hover 
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
      >
        <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.titleCell,
            setActivity ? classes.setActivityTitle : classes.secondColumnActivity,
            isLatestActivity ? classes.boldCell : "",
          )}
        >
          {aRow.name}
        </TableCell>
        {isDone && <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
            isLatestActivity ? classes.boldCell : "",
          )}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <p className={classes.completedWrapper}>
                {aRow.isPendingGrade ? "Pending Grade" : "Complete"}
              </p>
            </Grid>
            <Grid item>
              {aRow.isPendingGrade ? (
                <GradeIcon className={classes.gradeIcon} />
              ) : (
                <RatingIconProgress rate={rate} progressPage />
              )}
            </Grid>
          </Grid>
        </TableCell>}
        {!isDone && !(aRow.logs.contentSections.length === 0) && <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
            isLatestActivity ? classes.boldCell : "",
          )}
        >
          In Progress
        </TableCell>}
        {aRow.logs.contentSections.length === 0 && <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
            isLatestActivity ? classes.boldCell : "",
          )}
        >
          Not Started
        </TableCell>}
        {!(aRow.logs.contentSections.length === 0) && <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
            isLatestActivity ? classes.boldCell : "",
          )}
        >
          {progress}
        </TableCell>}
        {aRow.logs.contentSections.length === 0 && <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
            isLatestActivity ? classes.boldCell : "",
          )}
        >
        </TableCell>}
        <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
            isLatestActivity ? classes.boldCell : "",
          )}
        >
          {(isDone || aRow.logs.contentSections.length > 0) &&
            timeOrDate}
        </TableCell>
        {!isMyProgress && <TableCell
          style={{ minWidth: 150 }}
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
            isLatestActivity ? classes.boldCell : "",
          )}
        >
          {(isDone || aRow.logs.contentSections.length > 0) && time}
        </TableCell>}
        {isMyProgress && <TableCell
          align="center"
          className={clsx(
            classes.cell,
          )}
        >
          <ProgressButton
            rate={rate}
            completed={isDone}
            notStarted={aRow.logs.contentSections.length === 0}
            isBoldText={isLatestActivity}
            text={time}
            hover={hover}
            setHover={setHover}
            onClick={() => {
              if (isDone) {
                setShowConfirmation(aRow);
                return;
              }

              if (aRow.logs.lastActivityLogDate) {
                onPush(
                  `${aRow.logs.path}#${aRow.logs.latestContentSection.id}`
                );
              } else {
                onPush(aRow.logs.path);
              }
            }}
            shouldFlip={isMyProgress}
            showContentSection={showContentSection}
          />
        </TableCell>}
      </TableRow>
    </React.Fragment>
  );
};

const SetRow = ({
  onPush,
  set,
  clearProgress,
  isMyProgress,
  ratings,
  userId,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [showConfirmation, setShowConfirmation] = React.useState(null);
  const [hover, setHover] = React.useState(false);

  const activitiesToRender = set.activities.filter(
    (r) => r.logs.isDone || r.logs.contentSections.length > 0
  );

  if (activitiesToRender.length === 0) return null;

  const latestActivity = set.latestActivity;

  const d = latestActivity.logs.lastActivityLogDate
    ? new Date(latestActivity.logs.lastActivityLogDate)
    : new Date();
  const month = d.getMonth() > 8 ? d.getMonth() + 1 : `0${d.getMonth() + 1}`;
  const date = d.getDate() > 9 ? d.getDate() : `0${d.getDate()}`;
  const latestActivityLogDate = `${month}/${date}/${d.getFullYear()}`;

  const activitiesInSet = set.activities.filter(a => !a.isOptional);
  const isSetCompleted = !set.isIncomplete &&
    activitiesInSet.filter(
      (a) => a.logs && a.logs.isDone && a.logs.isDone && !a.isIncomplete
    ).length === activitiesInSet.length;

  const countActivitiesDone = activitiesInSet.filter((a) => a.logs && a.logs.isDone && !a.isIncomplete).length;
  let progress = `${countActivitiesDone} of ${activitiesInSet.length}`;

  let rate = null;
  if (ratings && ratings.length > 0) {
    const setRatings = ratings.filter((r) => r.set === set.slug && userId === r.userId);
    if (setRatings && setRatings.length > 0) {
      const averageRating =
        setRatings.reduce((total, cur) => total + cur.rating, 0) /
        setRatings.length;
      rate = {
        rating: averageRating >= 0.5 ? 1 : averageRating < -0.5 ? -1 : 0,
      };
    }
  }

  return (
    <React.Fragment>
      <ConfirmDialog
        content="Are you sure you want to redo this item? Your progress will be cleared."
        onCancel={() => {
          setShowConfirmation(null);
        }}
        onConfirm={() => {
          clearProgress(showConfirmation);
          setShowConfirmation(null);
        }}
        open={showConfirmation}
        title="Clear progress"
        okTitle="Agree"
      />

      <TableRow 
        className={classes.row}
        hover 
        key={set.id}
        sx={{
          "& .MuiTableRow-root:hover": {
            backgroundColor: "black"
          }
        }}
        onMouseEnter={() => setHover(true)} 
        onMouseLeave={() => setHover(false)}
        onClick={() => { setOpen(!open) }}
      >
        <TableCell
          align="center"
          className={clsx(
            classes.titleCell,
            classes.regularCell,
            classes.setTitle
          )}
        >
          <IconButton
            style={{color: colors.teal2 }}
            aria-label={`${open ? 'collapse' : 'expand'} row ${set.name}`}
            aria-expanded={open ? "true" : "false"}
            role="button"
            size="small"
            onFocus={() => setHover(true)}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRigthIcon />}
          </IconButton>
          {set.name}
        </TableCell>
        {isSetCompleted ? <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
          )}
        >
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <p className={classes.completedWrapper}>
                {set.isPendingGrade ? "Pending Grade" : "Complete"}
              </p>
            </Grid>
            <Grid item>
              {set.isPendingGrade ? (
                <GradeIcon className={classes.gradeIcon} />
              ) : (
                <RatingIconProgress rate={rate} progressPage />
              )}
            </Grid>
          </Grid>
        </TableCell> :
        <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
          )}
        >
          In Progress
        </TableCell>
        }
        <TableCell
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
          )}
        >
          {progress}
        </TableCell>
        <TableCell
          align="center"
          className={clsx(classes.regularCell, classes.cell)}
        >
          {latestActivityLogDate}
        </TableCell>
        {!isMyProgress && <TableCell
          style={{ minWidth: 150 }}
          align="center"
          className={clsx(
            classes.regularCell,
            classes.cell,
          )}
        >
        </TableCell>}
        {isMyProgress && <TableCell align="center" className={classes.cell}>
          <ProgressButton
            rate={rate}
            completed={isSetCompleted}
            notStarted={false}
            isBoldText={false}
            hover={hover}
            setHover={setHover}
            onClick={() => {
              if (!set.latestActivity) return;

              if (isSetCompleted) {
                setShowConfirmation(set);
              } else {
                onPush(
                  `${set.latestActivity.logs.path}#${set.latestActivity.logs.latestContentSection.id}`
                );
              }
            }}
            shouldFlip={isMyProgress}
            showContentSection
          />
        </TableCell>}
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table className={classes.table}>
              <TableBody>
                {activitiesInSet.map((aRow, index) => {
                  return (
                    <ActivityRow
                      key={index}
                      aRow={aRow}
                      set={set}
                      isMyProgress={isMyProgress}
                      classes={classes}
                      showConfirmation={showConfirmation}
                      onPush={onPush}
                      clearProgress={clearProgress}
                      ratings={ratings}
                      userId={userId}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const Row = ({
  row,
  onPush,
  isMyProgress,
  clearProgress,
  ratings,
  galleryProjects,
  isFacilitator,
  clubCode,
  isOpened,
  certificateRibbonIcon,
  userId,
}) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(isOpened ? true : false);

  const setWithProgress = row.sets.find(
    (r) => (r.logs && r.logs.shouldBeRendered) || r.shouldBeRendered
  );

  if (!setWithProgress) return null;

  const { completedContent, pendingGradingTotal, activitiesTotal } = row;

  const percent = (completedContent / activitiesTotal) * 100;

  const endsInCertificate = row.endsInCertificate;

  return (
    <React.Fragment>
      <TableRow 
        className={classes.row}
        key={row.id}
        hover 
        onClick={() => { setOpen(!open) }}
      >
        <TableCell
          align="center"
          className={clsx(classes.setCell, classes.firstColumn)}
        >
          {percent !== 100 && <SetProgress percent={percent} />}
          {percent === 100 && <CheckCircle aria-hidden="false" aria-label="full circle icon" className={classes.checkCircle} />}
          {endsInCertificate && <Box className={classes.iconWrapper}>
            <img className={percent !== 100 ? classes.certificateIcon : clsx(classes.certificateIcon, classes.certificateIconActivityCompleted)} src={certificateRibbonIcon.src} alt={certificateRibbonIcon.alt} />
          </Box>}
        </TableCell>
        <TableCell className={classes.titleSetCell}>
          <p className={classes.cellTitle}>{row.name}
          <IconButton
            style={{color: colors.teal2 }}
            aria-label={`${open ? 'collapse' : 'expand'} row ${row.name}`}
            aria-expanded={open ? "true" : "false"}
            role="button"
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRigthIcon />}
          </IconButton>
          </p>
          
          <br/>
          <p className={classes.cellText}>
            {`${completedContent} out ${activitiesTotal} activities are done`}
            {pendingGradingTotal
              ? ` | ${pendingGradingTotal} 
            ${pendingGradingTotal === 1 ? 'activity' : 'activities'
              } pending grade`
              : ''}
          </p>
        </TableCell>

        <TableCell
          align="center"
          className={classes.buttonWrapper}
        >
          {isMyProgress && (
            <Button
              className={classes.goToButton}
              aria-label="go to set"
              role="button"
              onClick={() => {
                onPush(`/gwc${row.path}`);
              }}
              endIcon={<ArrowForwardIcon />}
            >
              GO TO SET
            </Button>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ padding: 0 }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {row.sets.map((set, index) => {
              if (set.type === "set") {
                return (
                  <SetRow
                    key={index}
                    set={set}
                    onPush={onPush}
                    clearProgress={clearProgress}
                    isMyProgress={isMyProgress}
                    ratings={ratings}
                    galleryProjects={galleryProjects}
                    isFacilitator={isFacilitator}
                    clubCode={clubCode}
                    userId={userId}
                  />
                );
              } else if (set.type === "activity") {
                return (
                  <ActivityRow
                    key={index}
                    aRow={set}
                    isMyProgress={isMyProgress}
                    classes={classes}
                    clearProgress={clearProgress}
                    onPush={onPush}
                    showContentSection={true}
                    ratings={ratings}
                    setActivity={true}
                    requiredGrading={set.requiredGrading}
                    userId={userId}
                  />
                );
              }

              return null;
            })}
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

export default Row;
