import React from "react";

import { createMarkup } from "../../../utils/generic";

export default class ContentItem extends React.PureComponent {
  render() {
    const { id, title, content, subContent } = this.props;
    const isSubContent = !!subContent ? "content-item-sub" : "";
    return (
      <div className={`content-item ${isSubContent}`}>
        <div id={id} className="title">{title}</div>
        <div className="rich-text" dangerouslySetInnerHTML={createMarkup(content)}></div>
      </div>
    );
  }
}
