import { capitalizeFirstLetter } from './capitalizeFirstLetter';

const Titles =
{
  HQ: "HQ",
  default: "HQ | Girls Who Code",

  login: "Login",
  signup: "Sign up",
  "change-password": "Change Password",
  "forgot-password": "Forgot Password?",
  "set-password": "Set Password",

  "static-pages": "Static Pages",
  privacy: "Privacy Policy",
  "getting-started": "Getting Started",
  terms: "Terms of Service",
  "cookie-policy": "Cookies Policy",
  "community-partner-dashboard-faq": "Community Partner Dashboard FAQ",
  "international-community-partner-dashboard-faq": "International Community Partner Dashboard FAQ",
  "code-of-conduct": "Code of Conduct",

  "project-gallery": "Project Gallery",
  "submit-project": "Submit Your Project",
  "project-details": "Project Details",
  "public-profile": "Public Profile",
  "verify-certificate": "View Certificate",
  "user-profile": "User Profile",
  home: "My Programs",
  calendar: "My Calendar",
  "join-program": "Join Your Program",
  apply: "Apply",

  progress: "Progress",
  "progress-member": "Progress",
  members: "Members",
  attendance: "Attendance Tracker",
  messages: "Messages",
  "view-post": "View Post",
  "program-info": "Program Info",

  "cp-dashboard": "Community Partner Dashboard",
  "cp-resources": "Community Partner Resources",
  "renew-club": "Renew Club",
  welcome: "Welcome",
  "gwc-challenges": "GWC Challenges",
}

export function useTitle(title, takeExact = false) {
  let pageTitle = Titles.default;
  
  if (!!title && (Titles[title] || takeExact)) {
    pageTitle = `${Titles.HQ} | ${takeExact ? title : Titles[title]}`;
  }

  if (!!title && title.includes('static-pages/')) {
    pageTitle = title
        .split('/static-pages/')[1]
        .split('-')
        .map(word => capitalizeFirstLetter(word))
        .join(' ');

    pageTitle = `${Titles.HQ} | ${pageTitle}`;
  }
  
  document.title = pageTitle;
}