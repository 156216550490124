import cta from './Cta';
import * as FormFields from './FormFields';
import menu from './Menu';
import messaging from './Messaging';

const LoginSignup = `
  heading
  loginSubheader
  loginSubheaderClubs
  subheading
  termsAndConditions
  termsAndConditionsInternational
  tabs {
    ${menu}
  }
  loginFields {
    ${FormFields.anyFormField}
  }
  signupFields {
    ${FormFields.anyFormField}
  }
  loginForgotPasswordCTA {
    ${cta}
  }
  loginNeedHelpCTA {
    ${cta}
  }
  loginSubmitCTA {
    ${cta}
  }
  loginNoAccountLabel {
    ${messaging}
  }
  loginCreateAccountCTA {
    ${cta}
  }
  signupAllStepsBackLabel
  signupNationalityStepHeader
  signupNationalityStepDomesticCountry
  signupNationalityStepDomesticCta {
    ${cta}
  }
  signupNationalityStepInternationalCta {
    ${cta}
  }
  signupNationalityStepCountrySelection {
    ${FormFields.commonFieldAttributes}
    ${FormFields.selectField}
  }
  signupBirthStepHeader
  signupBirthStepDateInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  signupRoleStepHeader

  signupAccountInfoChildFirstNameInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  signupAccountInfoChildLastNameInput {
    ${FormFields.commonFieldAttributes}
    ${FormFields.formField}
  }
  signupAccountInfoRemoveAChildLabel
  signupAccountInfoAddAChildLabel
  signupApplicationDialogHeader
  signupApplicationDialogMessage
  signupApplicationDialogCloseLabel
  communicationConsent
  internationalDisclaimer
  signUpSubmitCTA {
    ${cta}
  }
  formMessages {
    ${messaging}
  }
  termsOfUseCTA {
    ${cta}
  }
  privacyPolicyCTA {
    ${cta}
  }
  cookiesPolicyCta {
    ${cta}
  }
  `;

export default LoginSignup;
