import React, { useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import clsx from 'clsx';
import {
  Box,
  CardContent,
  Card,
  Grid,
  CardActions,
  Button,
  Typography,
  Breadcrumbs,
} from "@material-ui/core/";
import history from '../../../../src/history';
import { makeStyles } from "@material-ui/core/styles";
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CheckCircle from '@material-ui/icons/CheckCircle';
import WarningIcon from '@material-ui/icons/Warning';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { ColorTheme } from '../../../utils/colorTheme';
import * as colors from "../../../theme/colors";
import PageTitle from "../../../components/page-title/pageTitle";
import CustomButton from "../../../components/customButton/customButton";
import Tabs from "./../Tabs/index";
import * as helpers from "../helpers";

import ViewAnswer from "./viewAnswer";

import GradeIcon from '../../../images/grade.svg?react';
import { monthNames } from "../../../utils/generic";
import { TASK_STATUSES } from '../../../utils/constants';
import { isFinishedHardClosureProgram } from '../../../utils/checkFinishedHardClosureProgram';


const useStyles = makeStyles((theme) => ({
  card: {
    borderBottom: "6px solid $grey-dark",
    boxSizing: "border-box",
    margin: 12,
    padding: 24,
    transition: "transform 150ms linear",
    height: "fit-content",
    width: "-webkit-fill-available",
    "&:hover": {
      transform: "scale(1.05, 1.05)",
    },
    "&:focus": {
      outline: "none",
      transform: "scale(1.05, 1.05)",
    },
  },
  cardСover: {
    height: 200,
  },
  img: {
    width: "80%",
    height: "60%",
  },
  cardTitle: {
    overflow: "hidden",
    fontWeight: 700,
    fontSize: 16,
    height: 48,
    boxOrient: 'vertical',
    lineClamp: 2,
    display: '-webkit-box'
  },
  cardContent: {
    padding: "16px 0 0 !important",
    display: "flex",
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  projectLink: {
    textDecoration: "none",
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  header: {
    margin: "15px auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
  },
  tabsSection: {
    margin: '-10px auto 15px -25px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: "block",
      marginLeft: '-10px',
    },
  },
  viewAllBtn: {
    fontWeight: 700,
    fontSize: 14,
    color: colors.teal2,
    marginLeft: 'auto'
  },
  tag: {
    paddingLeft: 10,
  },
  chip: {
    fontSize: 12,
    fontWeight: 500,
    color: colors.grayTheme,
    fontFamily: "'Roboto Mono',monospace",
  },
  cardActions: {
    justifyContent: "flex-end",
  },
  titleBox: {
    minHeight: 100,
    width: "100%",
    marginLeft: '-20px',
  },
  titleContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 20,
    minHeight: 100,
    flexWrap: "wrap",
    alignContent: "center",
  },
  contentItem: {
    display: 'flex',
  },
  titleLabel: {
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: colors.darkThemeBlueGray,
  },
  assignmentIconBox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  chatBubbleIcon: {
    marginLeft: 10,
    color: colors.lightThemeGrey,
  },
  itemLabel: {
    color: colors.lightThemeGrey,
  },
  checkmarkIcon: {
    color: ColorTheme.teal2.b,
    height: 22,
    marginLeft: 5,
  },
  warningIcon: {
    color: ColorTheme.orange3.b,
    height: 22,
    marginLeft: 5,
  },
  gradeIcon: {
    width: 20,
    height: 20,
    marginTop: 2,
  },
  answerIconBig: {
    height: 120,
    width: 120,
    paddingBottom: 10,
  },
  emptyPageText: {
    fontSize: 22,
    fontWeight: 700,
  },
  emptyPageCard: {
    width: '100%',
    height: '100%',
    minHeight: 'calc(100vh - 400px)',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignContent: 'center',
    textAlign: 'center',
    display: 'flex',
    padding: 15,
  },
  collectionTitle: {
    fontWeight: 600,
    fontSize: 24,
    color: colors.dartThemeBlueGray,
    textTransform: 'uppercase',
  },
  icon: {
    marginRight: 5,
  },
  titleItem: {
    display: 'flex',
  },
  titleWrapper: {
    flexWrap: 'nowrap',
  },
  breadCrumbsLink: {
    marginLeft: 10,
    color: colors.darkThemeGreen,
    fontWeight: "bold",
    fontSize: 14,
    textDecoration: "none",
    '&:hover': {
      color: colors.lightThemeGreen,
    },
  },
  breadCrumbsName: {
    color: colors.lightThemeGrey,
    fontWeight: "bold",
    fontSize: 14,
  },
  breadCrumbs: {
    marginTop: 20,
    marginLeft: '-5px',
  },
  nextUserBtnIcon: {
    height: 40,
    width: 40,
  },
  nextUserBtnWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nextUserBtn: {
    cursor: "pointer",
    color: colors.grayTheme,
    fontWeight: "bold",
    fontSize: 18,
    marginRight: 10,
  },
}));
const AnswerCard = ({ items, heading, classes, onClick, requiredGrading, answerGroup, getCommentsByAnswer, showUserHeader, question, itemKey, userId, getCommentsForAnswerByUser, getComments }) => {
  const isCommentsLoaded = items.find(e => Array.isArray(e.comments));
  if (getComments && !isCommentsLoaded && !items[0]?.answerGroupId) {
    showUserHeader ?
    getCommentsForAnswerByUser({
      programId: items[0].programId,
      userId,
      question,
      itemKey,
    }) :
    getCommentsByAnswer({ itemKey, programId: items[0].programId, question });
  }
  const item = answerGroup || Array.isArray(items) && items[0];
  const date = new Date(item.createdAt);
  const pendingGrade = item.status === TASK_STATUSES.review && requiredGrading;

  const answerLabel = useMemo(() => {
      if (!answerGroup && !requiredGrading) return "Complete";
      if (pendingGrade) return "Submitted | Pending Grade";

    switch (item.status) {
      case TASK_STATUSES.complete : return "Complete";
      case TASK_STATUSES.incomplete : return "Incomplete";
      default: return "Submitted";
    }
  }, [item.status, pendingGrade])

  const totalComments = answerGroup ? answerGroup.totalComments : Array.isArray(items) && items.map(e => e.totalComments || 0).reduce((prev, next) => prev + next);

  return (
    <Grid key={item.id} item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card
        className={classes.card}
        tabIndex="0"
        onClick={onClick}
      >
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid className={classes.titleItem} item xs={12} md={6}>
              <Grid className={classes.titleWrapper} container>
                <Grid item>
                  {requiredGrading ? <GradeIcon className={clsx(classes.gradeIcon, classes.icon)} /> : <AssignmentOutlinedIcon className={classes.icon} />}
                </Grid>
                <Grid item>
                  <Typography className={classes.titleLabel}>
                    {heading}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.contentItem} item xs={12} md={3}>
              <Typography>{answerLabel}</Typography>
              {answerLabel === "Complete" && <CheckCircle className={classes.checkmarkIcon} />}
              {answerLabel === "Incomplete" && <WarningIcon className={classes.warningIcon} />}
            </Grid>
            <Grid className={classes.contentItem} item xs={12} md>
              <Typography className={classes.itemLabel}>{totalComments || 0}</Typography>
              <ChatBubbleOutlineIcon className={classes.chatBubbleIcon} />
            </Grid>
            <Grid className={classes.contentItem} item xs={12} md>
              <Typography className={classes.itemLabel}>{`${date.getDate()} ${monthNames[date.getMonth()]}`}</Typography>
            </Grid>
          </Grid>
        </CardContent>
        <CardActions className={classes.cardActions} disableSpacing>
          <Button />
        </CardActions>
      </Card>
    </Grid>
  );
};

const Answers = ({
  user,
  curriculums,
  currentProgram,
  currentTab,
  setCurrentTab,
  isLoading,
  isUserProjects,
  showCertificates,
  openTextAnswers,
  getCommentsByAnswer,
  getCommentsForAnswerByUser,
  createAnswer,
  editedAnswer,
  setEditedAnswer,
  userId,
  navigateBack,
  nextUserProgressId,
  usersProjectsByProgram,
  answerGroups,
  isFacilitator,
  push,
  userFullName,
  programTypes,
}) => {
  const classes = useStyles();

  const [rows, setRows] = useState({});
  const [nodes, setNodes] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [programQuestions, setProgramQuestions] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [showUserHeader, setShowUserHeader] = useState(false);
  const [isFinishedProject, setIsFinishedProject] = React.useState(false);

  const { userAnswers, isLoadingComments, isLoadingAnswer } = openTextAnswers;

  const redirectUrl = useMemo(() => {
    const params =  new URLSearchParams(history.location.search);
    const backTo = params.get('backTo');

    return backTo ? `${backTo}${history.location.hash}` : null;
  }, [history])

  useEffect(() => {
    if (
      (!userAnswers) ||
      !curriculums ||
      !currentProgram ||
      !curriculums[currentProgram.curriculum.code]) {
      return;
    }

    const curriculum = curriculums[currentProgram.curriculum.code];
    const openTextQuestions = helpers.getOpenTextContentSections(curriculum);
    setProgramQuestions(openTextQuestions);

    const collections = helpers.getCurriculumCollections(curriculum);   
    const currentAnswerGroups = Object.values(answerGroups.userAnswerGroups || {});
    const collectionsWithAnswers = collections.map(collection => {
      const answerGroups = currentAnswerGroups.filter((group) => group?.collection === collection.id);
      // remove answer groups from open text sections
      const allContentSections = helpers.getOpenTextContentSections(collection);
      const openTextContentSections = allContentSections.filter(({ contentSection }) => !contentSection.contentSectionGroup);
      const openTextAnswers = openTextContentSections.sort((a, b) => a.contentSection.heading - b.contentSection.heading);
      const openTextItems = openTextContentSections.map(answer => {

        const itemKey = `${answer.activity.path}-${answer.contentSection.id}`;
        if (userAnswers && userAnswers[itemKey]) {
          return userAnswers[itemKey];
        }
      }).filter(Boolean);

      const groupOpenTextSections = helpers.getMapOfGroups(allContentSections);
      return {
        collection: collection.name,
        openTextAnswers,
        openTextItems,
        answerGroups,
        groupOpenTextSections
      }
    });

    setNodes(collectionsWithAnswers);
    setRows(userAnswers);
    setIsDataLoading(false);
  }, [userAnswers, curriculums, answerGroups]);

  useEffect(() => {
    if (userId && parseInt(userId, 10) !== user.id) {
      setShowUserHeader(true);
    }
  }, [user, userId]);

  useEffect(() => {
    if (!!currentQuestion) {
      const sectionId = getSectionId();
      if (!!sectionId) {
        if (!!userId) {
          push(`/progress/${currentProgram.code}/user/${userId}/view/answers/${sectionId}`);
        } else if (!!user) {
          push(`/progress/${currentProgram.code}/${user.id}/answers/${sectionId}`);
        }
      }
    }
  }, [currentQuestion])

  useEffect(() => {
    const groupOpenTextSections = helpers.getMapOfGroups(programQuestions);
    if ((!answerGroups?.isLoadingAnswerGroups && !isLoading && !!programQuestions.length && editedAnswer)) {
      const currentNode = programQuestions.find((node) => node.contentSection.id === editedAnswer);
      const itemKey = currentNode && `${currentNode.activity.path}-${currentNode.contentSection.id}`;
      if (currentNode?.contentSection?.contentSectionGroup) {
        const group = currentNode?.contentSection?.contentSectionGroup;
        const currentAnswerGroups = Object.values(answerGroups.userAnswerGroups || {});
        const answerGroup = currentAnswerGroups.find(answerGroupItem => {
          return Array.isArray(answerGroupItem.openTextAnswers) &&
          answerGroupItem.openTextAnswers.some(answer => answer.question === editedAnswer);
        });
        if (answerGroup) {
          setCurrentQuestion({ currentNode: groupOpenTextSections[`${group.id}-${currentNode?.activity.id}-${currentNode?.collection?.id}`], answerGroup })
        }
      } else {
        setCurrentQuestion({ itemKey, currentNode });
      }
    };
  }, [editedAnswer, programQuestions, answerGroups, userAnswers, userId]);

  useEffect(() => {
    if (!programTypes.length) return;
    const currentProgramTypeDetails = currentProgram?.programTypeDetails;
    const isFinishedHardClosureProject = isFinishedHardClosureProgram(currentProgramTypeDetails, currentProgram.projectEndDate, currentProgram.hardClosureEndDate);
    setIsFinishedProject(isFinishedHardClosureProject);
  }, [currentProgram, programTypes]);
  

  const selectedItem = userAnswers && currentProgram && currentQuestion
    ? userAnswers[currentQuestion.itemKey]
    : null;

  const keyDown = (e) => {
    if (e.key === "Enter") {
       e.target.click();
    }
    return;
  }

  const showProjectTab = usersProjectsByProgram?.some(user => user.userId === userId);

  const getSectionId = () => {
    if (!currentQuestion) return null;

    return currentQuestion.answerGroup?.id
      ? currentQuestion.answerGroup.openTextAnswers[0]?.question
      : currentQuestion.currentNode.contentSection.id;
  }

  const onNextMemberClick = () => {
    const sectionId = getSectionId();
    !!sectionId && setEditedAnswer(sectionId);
    navigateBack(nextUserProgressId, sectionId);
  }

  const getAnswerGroupAnswers = (groupAnswerId) => {
    const result = [];

    if (!userAnswers) {
      return result;
    }

    for (const item in userAnswers) {
      if (userAnswers[item][0]?.answerGroupId === groupAnswerId) {
        result.push(item);
      }
    }

    return result;
  }

  const isCurrentAnswerExists =
      (!!currentQuestion?.itemKey && !!userAnswers[currentQuestion.itemKey]) ||
      (!!currentQuestion?.answerGroup?.id && getAnswerGroupAnswers(currentQuestion?.answerGroup?.id).length);

  return (
    <Box className={classes.contentBox}>
      {!showUserHeader ?
        <Box className={classes.header}>
          <PageTitle title="Progress" isLoading={isLoading} />
        </Box> :
      (<>
        <Breadcrumbs
          className={classes.breadCrumbs}
          separator=" / "
          aria-label="breadcrumb"
        >
          <Link
            onKeyDown={keyDown}
            tabIndex={0}
            underline="hover"
            className={classes.breadCrumbsLink}
            onClick={() => { navigateBack(null) }}
            to="#"
          >
            Progress
          </Link>
          <Typography className={classes.breadCrumbsName}>
            {userFullName}
          </Typography>
        </Breadcrumbs>
        <Box className={classes.titleBox}>
          <Box className={classes.titleContent}>
            <Box className={classes.title}>
              <PageTitle title={userFullName} isLoading={isLoading} />
            </Box>
            <Box className={classes.nextUserBtnWrapper} >
              {nextUserProgressId && (
                <>
                <Typography
                  className={classes.nextUserBtn}
                  onClick={onNextMemberClick}
                >
                  {"Next Member"}
                </Typography>
                <CustomButton aria-label="Next Member" size="large" mode="secondary" onClick={onNextMemberClick}
                  className={classes.nextUserBtnIcon}
                >
                  <ArrowForwardIcon />
                </CustomButton>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </>
      )}
      <div className={classes.tabsSection}>
        <Tabs
          value={currentTab}
          showCertificateTab={showCertificates}
          showProjectTab={!showUserHeader ? isUserProjects : showProjectTab}
          showAnswers={true}
          onChange={(evt, newValue) => {
            setCurrentTab(newValue);
          }}
        />
      </div>
      {(currentQuestion && isCurrentAnswerExists)
        ?
        <ViewAnswer
          currentQuestion={currentQuestion}
          items={selectedItem}
          openTextAnswers={openTextAnswers}
          onBackClick={() => {
            push(`/progress/${currentProgram.code}/${userId ? `user/${userId}/view` : user.id}/answers/`);
            setCurrentQuestion(null);
            setEditedAnswer(null);
          }}
          createAnswer={createAnswer}
          isLoadingComments={isLoadingComments}
          isFinishedProject={isFinishedProject}
          push={push}
          disableResubmit={showUserHeader || isFacilitator}
          answerGroups={answerGroups}
          nodes={nodes}
          isLoadingAnswer={isLoadingAnswer}
          history={history}
          showBackBtn={isFinishedProject && !!editedAnswer && !!redirectUrl}
          redirectUrl={redirectUrl}
        />
        :
        <Grid container>
          {!isLoading && (!!Object.keys(rows).length && !!nodes.length && !currentQuestion ? nodes.map(({
            collection, openTextAnswers, openTextItems, answerGroups, groupOpenTextSections }) => {
            // find direct cs in curriculum by activity path and cs id for question
            if ((!openTextAnswers.length || !openTextItems.length) && !answerGroups.length) {
              return;
            }
            return <>
                <Typography component="h1" className={classes.collectionTitle}>
                  {collection}
                </Typography>
                {!!answerGroups.length && answerGroups.map((group) => {
                const currentNode = groupOpenTextSections[`${group.contentfulGroupId}-${group.activity}-${group.collection}`];
                const ansGroup = currentNode.find(({activity}) => activity?.id === group.activity)
                return  <AnswerCard
                    key={`${group.id}-${group.activity}`}
                    heading={group.name}
                    items={group.openTextAnswers}
                    user={user}
                    classes={classes}
                    onClick={() => {
                      currentNode && setCurrentQuestion({ currentNode, answerGroup: group })
                    }}
                    requiredGrading={ansGroup.contentSection.requiredGrading}
                    answerGroup={group}
                  />
                }
                )}
                {openTextAnswers.map((currentNode, index) => {
                  const itemKey = `${currentNode.activity.path}-${currentNode.contentSection.id}`;
                  const items = rows[itemKey];
                  const question =  currentNode.contentSection.id;

                  if (!items) {
                    return;
                  }

                  return <AnswerCard
                    getComments={!!editedAnswer}
                    itemKey={itemKey}
                    userId={userId}
                    showUserHeader={showUserHeader}
                    getCommentsByAnswer={getCommentsByAnswer}
                    getCommentsForAnswerByUser={getCommentsForAnswerByUser}
                    question={question}
                    key={`${itemKey}-${index}`}
                    heading={currentNode.contentSection.heading}
                    items={items}
                    user={user}
                    classes={classes}
                    onClick={() => {
                      const isCommentsLoaded = items.find(e => Array.isArray(e.comments));
                      if (!isCommentsLoaded) {
                        showUserHeader ?
                        getCommentsForAnswerByUser({
                          programId: currentProgram.id,
                          userId,
                          question,
                          itemKey,
                        }) :
                        getCommentsByAnswer({ itemKey, programId: currentProgram.id, question });
                      }
                      setCurrentQuestion({ itemKey, currentNode })
                    }}
                    requiredGrading={currentNode.contentSection.requiredGrading}
                  />;
                })}
              </>
          }) :
              <Card className={classes.emptyPageCard}>
                <CardContent>
                  {isDataLoading
                    ? <PageTitle isLoading={isDataLoading}/>
                    : <>
                      <AssignmentOutlinedIcon className={classes.answerIconBig}/>
                      <Typography className={classes.emptyPageText}>
                        {Object.keys(userAnswers).length
                            ? <>This member doesn't have this answer, yet!</>
                            : <>This member doesn't have any answers, yet!</>}
                      </Typography>
                    </>
                  }
                </CardContent>
              </Card>
          )}
        </Grid>}
    </Box>
  );
};

export default Answers;
