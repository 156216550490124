import React, { useState } from 'react';

import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import { Box, Paper, Typography, Divider, Hidden, Drawer, Tooltip } from '@material-ui/core';
import Check from '@material-ui/icons/Check';

import { get } from 'lodash';

import { getTheme } from "../../../utils/colorTheme";
import { formatEstimatedMinutes } from "../../../utils/generic";
import * as colors from '../../../theme/colors';
import quotesIcon from "../../../images/quotes-icon.svg";
import GradeIcon from '../../../images/grade.svg?react';

import TextCustomButton from "../../../components/text-button/text-button";
import CustomButton from "../../../components/customButton/customButton";
import TextButton from "../../../components/text-button/text-button";
import ConfirmDialog from '../../../components/ConfirmDialog';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { createMarkup } from "../../../utils/generic";

import { TooltipWrapper } from "../../../components/tooltip/tooltip";

const drawerWidth = 300;

const styles = makeStyles(theme => ({
  root: {
    marginTop: 15,
    display: 'flex',
  },
  grid: {
    marginTop: 15,
  },
  cardIcon: {
    maxHeight: 100,
    maxWidth: 100
  },
  cardIconBox: {
    alignItems: 'center',
    display: 'flex',
    height: 90,
    margin: '18px 10px 35px 10px',
    justifyContent: 'center',
    textAlign: 'center',
  },
  headerBox: {
    display: 'flex',
    justifyContent: "space-between",
    lineHeight: '70px',
    padding: '0px 10px',
  },
  activityBox: {
    display: 'flex',
    justifyContent: "space-between",
    lineHeight: '70px',
    alignItems: 'center',
    padding: '0px 10px',
    cursor: 'pointer',
    '&:hover': {
      background: colors.grey200,
    }
  },
  expandedHeader: {
    backgroundColor: colors.lightBlue,
  },
  paper: {
    boxShadow: "none",
    position: 'relative',
    padding: 20,
    margin: 10,
  },
  mainBox: {
    [theme.breakpoints.up('lg')]: {
      minWidth: props => `${props.sectionSize}%`,
      maxWidth: props => `${props.sectionSize}%`,
    },
    [theme.breakpoints.down('lg')]: {
      maxWidth: '95%',
      minWidth: '95%',
    }
  },
  contentBox: {
    display: "flex",
    justifyContent: 'flex-end',
  },
  goalsPaper: {
    boxShadow: "none",
    position: 'relative',
    padding: 20,
    margin: 10,
    minWidth: '30%',
  },
  overviewBox: {
    display: 'block',
    alignItems: 'center',
    padding: '0px 15px',
    width: '100%',
  },
  overviewLabel: {
    color: colors.darkThemeBlueGray,
    fontSize: 18,
    fontWeight: 'bold',
  },
  descriptionLabel: {
    color: colors.darkThemeBlueGray,
    fontSize: '1rem',
  },
  expandBtn: {
    cursor: "pointer",
    color: colors.darkThemeGreen,
  },
  expandBox: {
    display: "flex",
    cursor: "pointer",
    width: 24,
  },
  timerBox: {
    display: "inline-flex",
    alignItems: 'center',
  },
  csItemBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: "left",
    height: 40,
    marginLeft: 40,
    position: 'relative',
  },
  csItemDoneBox: {
    marginLeft: 15,
  },
  gradeIconNotDone: {
    marginLeft: 25,
  },
  csItemLabel: {
    color: colors.darkThemeGreen,
    fontSize: 16,
    width: "calc(100% - 148px)",
    fontWeight: 'bold',
    cursor: 'pointer',
    '&:hover': {
      color: colors.lightThemeGreen,
    }
  },
  labelText: {
    fontSize: 18,
    color: colors.darkThemeBlue,
    fontWeight: "bold",
    marginLeft: 10,
    [theme.breakpoints.down('md')]: {
      maxWidth: '55%',
    }
  },
  clockSmall: {
    marginRight: 10,
  },
  doneIconBox: {
    width: 14,
    height: 14,
    display: 'flex',
  },
  doneIcon: {
    fill: colors.lightThemeGreen,
    color: colors.lightThemeGreen,
    height: 14,
  },
  labelBox: {
    display: 'flex',
    alignItems: 'center',
    height: 70,
    [theme.breakpoints.down('xs')]: {
      height: 'auto',
    }
  },
  pieChart: {
    background: (props) =>
      `conic-gradient(
        ${colors.lightThemeGreen} 0,
        ${colors.lightThemeGreen} ${props.percentage}%,
        ${colors.teal} 0,
        ${colors.teal} 100%
      )`,
    position: 'relative',
    borderRadius: '50%',
    width: 50,
    height: 50,
    margin: 0,
  },
  pieChartBox: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pieChartMiddle: {
    width: 15,
    height: 15,
    position: 'absolute',
    background: colors.white,
    borderRadius: '50%',
  },
  drawerPaper: {
    width: drawerWidth,
    marginTop: 65,
    height: 'calc(100% - 65px)',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.2)'
  },
  facilitatorOnlyLabel: {
    height: 14,
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    fontWeight: 500,
    cursor: "text",
    "&:hover": {
      cursor: "text",
    },
    marginLeft: 15,
    marginRight: 5,
    textTransform: "uppercase",
    [theme.breakpoints.down('xs')]: {
      marginBottom: "4px",
      height: 'auto',
    }
  },
  sectionHtml: {
    alignItems: 'center',
    flexDirection: 'column',
    maxWidth: '100%',
    width: '100%',
    lineHeight: 1.8,
    '& ul': {
      "padding-left": 40,
    },
    '& li': {
      lineHeight: 1.8,
      paddingLeft: 10,
      "&::marker": {
        color: colors.darkThemeGreen,
      }
    },
    '& h1': {
      margin: 0,
    },
    '& h2': {
      margin: 0,
    },
    '& a': {
      color: props => props.accentColor,
      lineHeight: 1.8,
      fontWeight: 700
    },
    '& .figure-markdown': {
      display: 'table',
      margin: '7px 0 7px 10px',
    },
    '& .img-markdown': {
      cursor: 'pointer',
      maxHeight: '100%',
    },
    '& table': {
      border: '1px solid',
      borderColor: colors.lightThemeGreen,
      borderCollapse: 'collapse',
      color: 'grey',
      fontSize: '14px',
      '& th': {
        border: '1px solid',
        borderColor: colors.lightThemeGreen,
        backgroundColor: props => props.backColor,
        color: props => props.fontColor,
        fontSize: '18px',
        padding: 10,
        textAlign: 'center',
      },
      '& td': {
        border: '1px solid',
        borderColor: colors.lightThemeGreen,
        color: colors.dartThemeBlueGray,
        fontSize: '16px',
        lineHeight: 1.6,
        padding: 5,
        textAlign: 'center',
      }
    },
    '& figcaption': {
      textAlign: 'right',
    },
    '& >p': {
      color: colors.darkThemeBlueGray,
      fontSize: '1rem',
      lineHeight: 1.8,
    },
    '& h3': {
      fontSize: '20px',
      fontWeight: 'bold',
      margin: 0,
    },
    '& .blockquote-container': {
      position: 'relative',
      '& .quotes': {
        borderRadius: '50%',
        display: 'block',
        height: '40px',
        left: '17px',
        position: 'absolute',
        top: '-19px',
        width: '40px',
        zIndex: 1,
        backgroundColor: props => props.accentColor,
        "&::before": {
          background: `url(${quotesIcon}) no-repeat`,
          backgroundImage: `url(${quotesIcon}) no-repeat`,
          color: colors.white,
          content: "",
          height: 15,
          left: 'calc(50% - 7.5px)',
          position: 'absolute',
          top: 'calc(50% - 7.5px)',
          width: '15px'
        }
      }
    },
    '& pre > code': {
      overflowX: 'auto',
      display: 'flow-root !important'
    },
    '& details': {
      backgroundColor: '#ffffff',
      borderRadius: 5,
      border: props => `1px solid ${props.accentColor}`,
      margin: '10px 0',
      padding: 10,

      '&:focus-within': {
        border: '2px solid #000000',
        padding: 9
      },

      '& > summary': {
        outline: 'none',
        cursor: 'pointer',
        color: props => props.accentColor,
        fontWeight: 700
      }
    },
  },
  expandContainer: {
    width: 30,
  },
  clockContainer: {
    width: 40,
    display: 'flex',
  },
  timeContainer: {
    width: 70,
    display: 'flex',
    justifyContent: 'center',
  },
  facilitatorOnly: {
    fontSize: 14,
    display: 'flex',
    margin: 10,
    cursor: 'pointer',

    "& svg": {
      fontSize: 16,
      marginRight: 5
    }
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'block',
    }
  },
  showOptionalBtn: {
    marginTop: 10,
    marginLeft: 25,
    textTransform: "none",
    "&:focus-visible": {
      outline: `2px auto ${colors.grey400}`,
    },
  },

  optionalLabel: {
    height: 14,
    fontSize: 10,
    fontFamily: "'Roboto Mono',monospace",
    fontWeight: 500,
    textTransform: "uppercase",
    alignSelf: "center",
    marginLeft: 15,
    marginRight: 5,
    textTransform: "uppercase",
    [theme.breakpoints.down('xs')]: {
      marginLeft: "-10px",
    },
    backgroundColor: colors.lightGrey,
    color: colors.white,
    '&:hover': {
      backgroundColor: colors.lightGrey,
      color: colors.white,
    },
  },
  actionBtn: {
    "&:focus": {
      outline: `2px auto ${colors.grey400}`,
    },
  },
  gradeIcon: {
    width: 20,
    height: 20,
    marginTop: 5,
  },
}));

const noStudentNodesLabel = "There is no student activity to start"; 

const getIsSectionDone = (contentSection, quizesAnswers, openTextAnswers, userActivitiesInfo, url, isDone) => {
  const itemKey = `${url}-${contentSection.id}`;
  const sectionType = get(contentSection, ['__typename']);

  const simpleOrOptionalSection = (sectionType !== 'QuizQuestion' || contentSection.isOptionalQuiz) && (!contentSection.isOpenTextAnswer || contentSection.isOptionalTextAnswer);

  const quizAnswer = get(quizesAnswers, [`${contentSection.id}`]);
  if (!contentSection.isOptionalQuiz && Array.isArray(quizAnswer) && !!quizAnswer[0].isCorrect) {
    return true;
  }

  const currentAnswers = get(openTextAnswers.userAnswers, [`${itemKey}`], []);
  if (currentAnswers.length) {
    if (!contentSection.isOptionalTextAnswer && (contentSection.requiredGrading && currentAnswers[0].status === 1 || currentAnswers[0].status === null) || (contentSection.isOpenTextAnswer && !contentSection.requiredGrading)) {
      return true;
    }
  }

  if (simpleOrOptionalSection && (isDone || (userActivitiesInfo.some(item => item?.contentSection === contentSection.id)))) {
    return true;
  }

  return false;
}

const Set = (props) => {
  const {
    item,
    defaultActivityIcon,
    onRedoClick,
    onResumeClick,
    onStartClick,
    userActivitiesInfo,
    classes,
    labelBackground,
    labelFontColor,
    programRoleLabel,
    push,
    isAllNodesFacilitatorsOnly,
    openTextAnswers,
    userQuizesAnswers,
  } = props;

  const nodeTheme = null;
  const clockIconRef = React.useRef(null);
  const clockIconParentRef = React.useRef(null);
  const [expandedItems, setExpandedItems] = useState({});
  const [showGoals, setShowGoals] = useState(false);
  const [showOptionalActivities, setShowOptionalActivities] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const cardTheme = getTheme(nodeTheme);

  const renderActionBtn = (percentageInSet, studentNodes, node, isStarted) => {
    const hasStudentNodes = studentNodes.length > 0;

    return (<>
      {!isStarted &&
        <TooltipWrapper
          title={!hasStudentNodes ? noStudentNodesLabel : null}
        >
          <div>
            <CustomButton
              mode={"primary"}
              label={"Start"}
              disabled={!hasStudentNodes}
              className={cardClasses.actionBtn}
              onClick={() => onStartClick(studentNodes[0])}
            />
          </div>
        </TooltipWrapper>}

      {isStarted && percentageInSet !== 100 &&
        <CustomButton className={cardClasses.actionBtn} onClick={() => onResumeClick(node)} label={"Resume"} />}

      {percentageInSet === 100 && <CustomButton
        className={cardClasses.actionBtn}
        variant="contained"
        label={"Redo"}
        onClick={() => setShowConfirmation(true)}
      />}
      {!!percentageInSet && <Box className={cardClasses.pieChartBox}>
        <div className={cardClasses.pieChart}></div>
        <Box className={cardClasses.pieChartMiddle} />
        <ConfirmDialog
          content="Are you sure you want to redo this item? Your progress will be cleared."
          onCancel={() => { setShowConfirmation(false); }}
          onConfirm={() => {
            setShowConfirmation(false);
            onRedoClick(item);
          }}
          open={showConfirmation}
          title="Clear progress"
          okTitle="Agree"
        />
      </Box>}</>)
  }


  const itemNodes = Object.values(item ? item.nodes : []);
  const studentNodes = itemNodes.filter(e => e.permissionLevel !== 'Facilitators/Teachers only');
  const activitiesLogs = userActivitiesInfo
    .filter((r) => !!studentNodes.find((e) => r.title === e.slug));
  const lastActivityLog = !!activitiesLogs.length ? activitiesLogs[activitiesLogs.length - 1] : null;
  const fiterOptionalActivities = studentNodes.filter(a => !a.isOptional);
  const doneNodes = fiterOptionalActivities.filter(r => !!activitiesLogs.find(e => e.title === r.slug && !!e.isDone)).length;
  const percentageInSet = !!doneNodes ? (doneNodes / fiterOptionalActivities.length) * 100 : 0;
  const isOptionalActivitiesPresent = itemNodes.find(item => item.isOptional);
  const isStarted = !!activitiesLogs.length;
  let node = null;
 
  if (lastActivityLog) {
    node = lastActivityLog ? studentNodes.find(e => e.slug === lastActivityLog.title) : {};
  }

  const isGoalsExist = !!item && !!item.goals && !!item.goals.length;
  const sectionSize = isGoalsExist ? 70 : 80;

  const cardClasses = styles({
    backColor: cardTheme.b,
    fontColor: cardTheme.f,
    accentColor: cardTheme.a,
    percentage: percentageInSet,
    sectionSize,
  });
  const icon = item.icon || defaultActivityIcon;



  return (
    <Box className={cardClasses.root}>
      <Box className={cardClasses.mainBox}>
        {isGoalsExist && <Hidden only={['lg', 'xl']}>
          <Box className={cardClasses.contentBox}>
            <TextCustomButton onClick={() => setShowGoals(true)}>Show more</TextCustomButton>
          </Box>
        </Hidden>}
        <Paper className={cardClasses.paper}>
          <Box className={cardClasses.headerBox}>
            <Box className={cardClasses.cardIconBox}>
              <img src={icon.src} alt={icon.alt} className={cardClasses.cardIcon} />
            </Box>
            <Box className={cardClasses.overviewBox}>
              <Typography className={clsx(cardClasses.sectionHtml, cardClasses.descriptionLabel)} dangerouslySetInnerHTML={createMarkup(item.introduction)} />
            </Box>

            <Box>
              {renderActionBtn(percentageInSet, studentNodes, node, isStarted)}
            </Box>
          </Box>

          <Divider />
          {!!itemNodes && itemNodes.map((node) => {
            const csNodes = node && node.contentSections ? node.contentSections : [];
            const showExpand = !!csNodes.length && csNodes.length > 1;
            const isExpanded = !!expandedItems[node.id];
            const activityLogs = userActivitiesInfo.filter((r) => r.title === node.slug);
            const isDone = activityLogs.find(e => e.isDone);
            const isActivityDone = isDone && csNodes.map(cs => {
              return getIsSectionDone(cs, userQuizesAnswers?.quizesAnswers, openTextAnswers, userActivitiesInfo, node.path, isDone);
            }).every(e => !!e);
            const isRenderTime = !!node.estimatedTimeInMinutes;
            const isFacilitatorsOnly = node.permissionLevel.indexOf('Facilitators/Teachers only') !== -1;
            const optionalActivity = node.nodeType === 'activity' && node.isOptional;

            return (
              ((optionalActivity && showOptionalActivities) || !optionalActivity) && <Box key={`activity-${node.id}`}>
                <Box ref={clockIconParentRef} className={isExpanded ? clsx(cardClasses.expandedHeader, cardClasses.activityBox) : cardClasses.activityBox}
                  tabIndex={0}
                  onClick={() => {
                    if (showExpand) {
                      setExpandedItems({ ...expandedItems, [node.id]: !expandedItems[node.id] })
                    } else {
                      // navigare to first cs if there is only one or to node path if activity without cs;
                      const path = !!csNodes.length ? `/gwc${node.path}#${csNodes[0].id}` : `/gwc${node.path}`;
                      // dont enforce presentation view for facilitators only node;
                      const enforcePresenter = !!csNodes.length ? true : node.permissionLevel !== "Facilitators/Teachers only";

                      push(path, { enforcePresenter });
                    }
                  }}>
                  <Box className={cardClasses.labelBox}>
                    <Box className={cardClasses.doneIconBox}>
                      {!!isActivityDone && <CheckCircleIcon hidden={!!isActivityDone} className={cardClasses.doneIcon} />}
                    </Box>
                    <Box className={cardClasses.labelContainer}>
                      <Typography className={cardClasses.labelText}>{node.name}</Typography>
                      {isFacilitatorsOnly && !isAllNodesFacilitatorsOnly && <TextButton
                        tabIndex="-1"
                        className={cardClasses.facilitatorOnlyLabel}
                        style={{ color: labelFontColor, backgroundColor: labelBackground }}
                        label={programRoleLabel}
                      />}
                      {optionalActivity && <TextButton
                        className={cardClasses.optionalLabel}
                        label="Optional"
                        tabIndex="-1"
                      />}
                    </Box>
                  </Box>
                  <Box className={cardClasses.timerBox}>
                    <Box className={cardClasses.clockContainer}>
                      {!!isRenderTime && <QueryBuilderIcon ref={clockIconRef} className={cardClasses.clockSmall} />}
                    </Box>
                    <Box className={cardClasses.timeContainer}>
                      {!!isRenderTime && <Box>{formatEstimatedMinutes(node.estimatedTimeInMinutes)}</Box>}
                    </Box>
                    <Box className={cardClasses.expandContainer}>
                      {showExpand ? <Box className={cardClasses.expandBox} sel="expand-button" onClick={() => setExpandedItems({ ...expandedItems, [node.id]: !expandedItems[node.id] })}>
                        {isExpanded ? <ExpandMoreIcon className={cardClasses.expandBtn} />
                          : <KeyboardArrowRightIcon className={cardClasses.expandBtn} />}
                      </Box> : <Box className={cardClasses.expandBox} />}
                    </Box>
                  </Box>
                </Box>
                {isExpanded &&
                  <Box>
                    {!!csNodes.length && csNodes.map((cs, index) => {
                      const csTitle = cs.__typename === "Surveys" ? "Survey" : (cs.heading || cs.header || `Section ${index + 1}`);
                      const isStudentCs = !cs.onlyVisibleToFacilitators;
                      const isSectionDone = getIsSectionDone(cs, userQuizesAnswers?.quizesAnswers, openTextAnswers, userActivitiesInfo, node.path);
                      return (
                        <Box key={`contentSection-${cs.id}`} className={!isSectionDone ? cardClasses.csItemBox : clsx(cardClasses.csItemBox, cardClasses.csItemDoneBox)}>
                          {!!isSectionDone && <Check hidden={!!isSectionDone} className={cardClasses.doneIcon} />}
                          <Typography
                            className={cardClasses.csItemLabel}
                            onClick={() => {
                              push(`/gwc${node.path}#${cs.id}`, { enforcePresenter: isStudentCs });
                            }}>{csTitle}</Typography>
                          {!isStudentCs && <Tooltip title={programRoleLabel}>
                            <Box className={cardClasses.facilitatorOnly}>
                              <VisibilityOffIcon />
                            </Box>
                          </Tooltip>}
                         {cs.requiredGrading && <Box>
                          <GradeIcon className={!isSectionDone ? clsx(cardClasses.gradeIcon, cardClasses.gradeIconNotDone) : cardClasses.gradeIcon} />
                         </Box>}
                        </Box>
                      );
                    })}
                  </Box>}

                <Divider className={classes.dividerItem} />
              </Box>);
          })}
          {isOptionalActivitiesPresent && <TextCustomButton className={cardClasses.showOptionalBtn}
            onClick={() => setShowOptionalActivities(showOptionalActivities => !showOptionalActivities)}
            >{`${showOptionalActivities ? "Hide" : "Show"} Optional Resources`}</TextCustomButton>}
        </Paper>
      </Box>
      <Hidden only={['xs', 'sm', 'md']}>
        {isGoalsExist && <Paper className={cardClasses.goalsPaper}>
          <Box className={cardClasses.sectionHtml} dangerouslySetInnerHTML={createMarkup(item.goals)} />
        </Paper>}
      </Hidden>
      <Drawer
        classes={{
          paper: cardClasses.drawerPaper,
        }}
        variant="temporary"
        anchor={'right'}
        open={showGoals}
      >
        {isGoalsExist && <Paper className={cardClasses.goalsPaper}>
          <TextCustomButton onClick={() => setShowGoals(false)}>Hide More Info</TextCustomButton>
          <Typography className={cardClasses.overviewLabel}>Goals:</Typography>
          <Box className={cardClasses.sectionHtml} dangerouslySetInnerHTML={createMarkup(item.goals)} />
        </Paper>}
      </Drawer>
    </Box>
  );
};

export default Set;