import React, { useEffect } from "react";

import ReactGA from "react-ga";
import CacheBuster from 'react-cache-buster';
import { Switch, Route, Redirect } from "react-router-dom";
import { ConnectedRouter } from 'connected-react-router';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { SnackbarProvider } from 'notistack';

import CurriculumLoader from "./components/CurriculumLoader";
import checkActivity from './activity';
import history from './history';
import AuthenticatedRoute from './components/AuthenticatedRoute';
import Home from './containers/Home/home';
import Curriculum from './containers/Curriculum';
import LoginSignUp from './containers/LoginSignUp';
import JoinClub from "./containers/JoinProgram/joinClub";
import GettingStarted from "./pages/getting-started/gettingStarted";
import StaticPages from "./pages/static/staticPages";
import NavBar from './components/NavBar';
import ProjectGallery from "./pages/project-gallery/project-gallery";
import Project from "./pages/project/project";
import UserProfile from "./pages/user-profile/user-profile";
import RenewClub from './pages/renew-club/renew-club';
import ClubProfile from "./containers/ClubProfile";
import PublicProfile from "./pages/public-profile/public-profile";
import Announcement from './containers/Announcement/Announcement';
import AnnouncementView from './containers/Announcement/AnnouncementView';
import ForgotPassword from './containers/ForgotPassword';
import ChangePassword from './containers/ChangePassword';
import AppLoader from "./containers/AppLoader";
import Notification from './containers/NotificationService/Notification';
import CommunityPartnerhip from './containers/CommunityPartnership/communityPartnership';
import CommunityPartnerhipResources from './containers/CommunityPartnership/CommunityPartnershipContainer/CommunityPartnerhipResources';
import SubmitProjectGallery from './containers/SubmitProjectGallery';
import Apply from './containers/ApplyPage';
import StartClub from './containers/ApplyPage/startClub/apply.page';
import Attendance from './containers/Attendance/attendance.page';
import SetPassword from './containers/SetPassword';
import Members from './containers/Members';
import Progress from './containers/Progress';
import Calendar from './containers/Calendar';
import UserProgress from './containers/UserProgress';
import LandingPage from './containers/LandingPage';
import VerifyCertificate from "./containers/VerifyCertificate";
import UserwayAccessibilityWidget from './components/userwayAccessibilityWidget/userwayAccessibilityWidget.js';
import GWCChallenge from "./containers/GWCChallenge";
import { logout } from './activity';

import { makeStyles } from "@material-ui/core/styles";
import * as colors from "./theme/colors";
import { ErrorOutlineOutlined } from '@material-ui/icons';

import useDeviceDetect from "./utils/deviceDetect";
import { checkIsClubsDomain } from './utils/generic';
import * as tokenStorage from './tokenStorage';
import config from '../package.json';

const clearSession = () => {
  alert('Session was cleared');
  logout();
};

const useStyles = makeStyles(() => ({
  containerRoot: {
    '& .MuiSnackbarContent-message': {
      paddingRight: '33px',
      whiteSpace: 'pre-line',
    },
    '& .MuiSnackbarContent-action': {
      position: 'absolute',
      right: 13,
      top: 3
    },
    '& .MuiIconButton-root': {
      padding: '11px'
    },
    '& .MuiSvgIcon-root': {
      height: '24px'
    }
  },
  errorIcon: {
    marginRight: '12px'
  },
  success: { backgroundColor: colors.darkThemeGreen },
  error: {
    backgroundColor: colors.red3Theme,
    maxWidth: 550,
  },
  infoPrivacy: {
    backgroundColor: colors.white,
    maxWidth: 350,
    '& .MuiSvgIcon-root': {
      display: 'none',
    },
    '& .MuiSnackbarContent-message': {
      padding: 0,
      whiteSpace: 'initial',
    },
  },
  info: {
    backgroundColor: '#E5F6FD',
    color: colors.dartThemeBlueGray,
  },
}));

const TRACKING_ID = window._env_.REACT_APP_GOOGLE_ANALYTIC_ID;

function App() {
  const styles = useStyles();
  const isMobile = useDeviceDetect();
  const isClubsDomain = checkIsClubsDomain();

  history.listen((location) => {
    if (!!TRACKING_ID) {
      ReactGA.initialize(TRACKING_ID, {
        titleCase: false,
        gaOptions: {
          siteSpeedSampleRate: 100,
        },
      });
      ReactGA.set({ page: location.pathname });
      ReactGA.pageview(location.pathname);
    }

    checkActivity();
  });

  useEffect(() => {
    checkActivity();
  }, []);

  const token = tokenStorage.getToken();
  const { pathname, search } = window.location;
  const redirectPath = !!pathname && typeof pathname === 'string' && !pathname.includes('/home');
  const landingUrl = redirectPath ? `/${search}`: '/';
  const userwayAccessibilityWidgetApiKey = window._env_.REACT_APP_USERWAY_ACCESSIBILITY_WIDGET_API_KEY

  return (
    <CacheBuster
      currentVersion={config.version}
      isVerboseMode={false}
      isEnabled={true}
    >
      <div className="App">
        {!!userwayAccessibilityWidgetApiKey && <UserwayAccessibilityWidget apiKey={userwayAccessibilityWidgetApiKey} />}
        <GoogleOAuthProvider clientId={window._env_.REACT_APP_GOOGLE_CLIENT_ID}>
          <SnackbarProvider
            classes={{
              variantSuccess: styles.success,
              variantInfo: styles.info,
              variantError: styles.error,
              variantInfoPrivacy: styles.infoPrivacy,
              containerRoot: styles.containerRoot,
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            iconVariant={{
              error: <ErrorOutlineOutlined className={styles.errorIcon}  />
            }}
          >
            <ConnectedRouter history={history}>
              <AppLoader>
                <NavBar isMobile={isMobile} />
                <Notification/>
                <CurriculumLoader>
                  <div id="maincontent"></div>
                  <Switch>
                    <Route
                      component={clearSession}
                      exact
                      path={["/clear-session", "/logout"]}
                    />
                    <Route path="/login" exact component={LoginSignUp} />
                    <Route path="/" exact component={LandingPage} />
                    <Route path={["/facilitatorInvitation/:facilitatorInvitationToken", "/invitation/:invitationToken"]} exact component={LoginSignUp} />
                    <Route path="/community-partner-dashboard-faq" exact component={GettingStarted}></Route>
                    <Route path="/international-community-partner-dashboard-faq" exact component={GettingStarted}></Route>
                    <Route path="/terms" exact component={GettingStarted}></Route>
                    <Route path="/privacy" exact component={GettingStarted}></Route>
                    <Route path="/cookie-policy" exact component={GettingStarted}></Route>
                    <Route path="/static-pages/:page" component={StaticPages}></Route>
                    <Route path="/code-of-conduct" exact component={GettingStarted}></Route>
                    <Route path="/forgot-password" exact component={ForgotPassword}></Route>
                    <Route path="/set-password/:token" exact component={SetPassword}></Route>
                    <Route path="/change-password/:token" exact component={ChangePassword}></Route>
                    <Route path="/verify/:token" exact component={VerifyCertificate}></Route>
                    <Route path="/gwc-challenges" exact component={GWCChallenge}></Route>

                    {!isClubsDomain ? <Route path="/project-gallery/:projectId" component={Project}></Route> :
                      <AuthenticatedRoute path="/project-gallery/:projectId" component={Project}></AuthenticatedRoute>}
                    {!isClubsDomain ? <Route path="/project-gallery" component={ProjectGallery}></Route> :
                      <AuthenticatedRoute path="/project-gallery" component={ProjectGallery}></AuthenticatedRoute>}

                    <AuthenticatedRoute path="/attendance" exact component={Attendance}/>
                    <AuthenticatedRoute path="/messages" exact component={Announcement}/>
                    <AuthenticatedRoute path={["/loop/:programCode/post/:postId", "/post/:postId"]} exact component={AnnouncementView}/>
                    <AuthenticatedRoute path={["/join-program", "/join-program/:childId", "/join-program/clubCode/:clubCode", "/join-club/:invitationToken","/join-program/qrCodeInvitation/:qrClubCode" ]} exact component={JoinClub} />
                    <AuthenticatedRoute path="/join-loop" exact component={JoinClub} />
                    <AuthenticatedRoute path="/user-profile" exact component={UserProfile} />
                    <AuthenticatedRoute path="/profile/:userId" exact component={PublicProfile} />
                    <AuthenticatedRoute path="/club-profile/:programCode" component={ClubProfile} />
                    <AuthenticatedRoute path="/new-project" exact component={SubmitProjectGallery} />
                    <AuthenticatedRoute path="/home" exact component={Home}/>
                    <AuthenticatedRoute path="/gwc-club-application" exact component={Apply}/>
                    <AuthenticatedRoute path="/start-club-application" exact component={StartClub}/>
                    <AuthenticatedRoute path="/members/:clubCode" exact component={Members}/>
                    <AuthenticatedRoute path="/calendar" exact component={Calendar}/>
                    <AuthenticatedRoute path="/renew-club/:code" exact component={RenewClub}/>

                    <AuthenticatedRoute path="/gwc" component={Curriculum} />
                    <AuthenticatedRoute path="/community-partnership/resources/community-partner-resources" component={CommunityPartnerhipResources}/>
                    <AuthenticatedRoute path="/community-partnership" exact component={CommunityPartnerhip}/>
                    <AuthenticatedRoute path={["/progress/:clubCode",
                      "/progress/:clubCode/view/:tab/:id?/:progressInputParam?",
                      "/progress/:clubCode/user/:userId/view/:tab?/:id?",
                      "/progress/:clubCode/user/:userId/"]}
                      exact component={Progress}/>
                    <AuthenticatedRoute path="/progress/:clubCode/:userId/:tab?/:id?" exact component={UserProgress}/>

                    {/* Redirect if none of the routes matches */}
                    <Route render={() => <Redirect to={!!token ? "/home" : landingUrl} />} />
                  </Switch>
                </CurriculumLoader>
              </AppLoader>
            </ConnectedRouter>
          </SnackbarProvider>
        </GoogleOAuthProvider>
      </div>
    </CacheBuster>
  );
}

export default App;
