import * as Yup from 'yup';
import { filterFieldWithReturnKey } from '../../../utils/generic';

export default (page, role, content) => {
  const schemas = getSchemas(content);
  if (page === 1 && role === 10) {
    return Yup.object({
      ...schemas[page],
    });
  }

  if (page === 2) {
    return Yup.object({
      ...schemas[2],
    });
  }

  return Yup.object(schemas[page]);
};

const getSchemas = (content) => {
  return [
    {
      country: Yup.string().required(content.signupNationalityStepCountrySelection.accessibleInstructions),
      birthDate: Yup.string().required(filterFieldWithReturnKey(content.signupBirthStepDateInput.validationErrors, "messageCode", "requiredField", "message")),
      // role: Yup.string().required('Required'),
      termsAndConditions: Yup.boolean()
        .test(
          'is-true',
          filterFieldWithReturnKey(content.formMessages, "messageCode", "termsAndConditionsRequired", "message"),
          value => value === true),
    },
    {
      termsAndConditions: Yup.boolean()
        .test(
          'is-true',
          filterFieldWithReturnKey(content.formMessages, "messageCode", "termsAndConditionsRequired", "message"),
          value => value === true),
    },
    {
      clubCode: Yup.string().required("Required"),
    }
  ];
}
