import React, { useEffect, useState } from "react";
import  { useHistory }  from "react-router-dom";
import clsx from 'clsx';
import {
  Box,
  CardContent,
  Card,
  Grid,
} from "@material-ui/core/";
import { makeStyles } from "@material-ui/core/styles";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/ErrorRounded';

import { ColorTheme } from '../../../utils/colorTheme';
import * as colors from "../../../theme/colors";
import PageTitle from "../../../components/page-title/pageTitle";
import Tabs from "../../UserProgress/Tabs/";
import Typography from "@material-ui/core/Typography";
import * as helpers from "../../UserProgress/helpers";
import UserAnswers from "./userAnswers";
import GradeIcon from '../../../images/grade.svg?react';
import { FilterBlock } from "../../../containers/Progress/index";

const useStyles = makeStyles((theme) => ({
  card: {
    borderBottom: "6px solid $grey-dark",
    boxSizing: "border-box",
    cursor: "pointer",
    margin: 12,
    padding: 24,
    transition: "transform 150ms linear",
    height: "fit-content",
    width: "-webkit-fill-available",
    "&:hover": {
      transform: "scale(1.05, 1.05)",
    },
    "&:focus": {
      outline: "none",
      transform: "scale(1.05, 1.05)",
    },
  },
  cardContent: {
    display: "flex",
    alignItems: 'center',
    [theme.breakpoints.down('xs')]: {
      display: "block",
    },
  },
  contentBox: {
    width: "95%",
    margin: "auto",
  },
  header: {
    margin: "15px auto",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tabsSection: {
    margin: '-10px auto 15px -25px',
    display: 'flex',
    [theme.breakpoints.down('xs')]: {
      display: "block",
      marginBottom: '0px',
      marginLeft: '-10px',
    },
  },
  titleLabel: {
    fontSize: 18,
    fontWeight: 500,
    display: '-webkit-box',
    '-webkit-line-clamp': 2,
    '-webkit-box-orient': 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: colors.darkThemeBlueGray,
  },
  assignmentIconBox: {
    display: 'flex',
    alignItems: 'center',
    marginRight: 10,
  },
  chatBubbleIcon: {
    marginLeft: 10,
    color: colors.lightThemeGrey,
  },
  itemLabel: {
    color: colors.lightThemeGrey,
  },
  collectionTitle: {
    fontWeight: 600,
    fontSize: 24,
    color: colors.dartThemeBlueGray,
    textTransform: 'uppercase',
  },
  doneIcon: {
    fill: ColorTheme.teal2.b,
    color: ColorTheme.teal2.b,
  },
  notDoneIcon: {
    fill: ColorTheme.red4.b,
    color: ColorTheme.red4.b,
  },
  roundIcon: {
    fontSize: '1.2rem',
    marginBottom: '-3px',
    marginLeft: 5,
  },
  gradeIcon: {
    width: 20,
    height: 20,
  },
  icon: {
    marginRight: 5,
    marginTop: 3,
    [theme.breakpoints.down('md')]: {
      marginTop: 3,
    },
  },
  titleItem: {
    display: 'flex',
  },
  titleWrapper: {
    flexWrap: 'nowrap',
  }
}));

const formatGroups = (groups) => {
  if (!groups.length) return;
  const answerGroups = groups.flat().reduce((acc, group) => {
    const { contentfulGroupId, userId } = group;

    if (!acc[contentfulGroupId]) {
      acc[contentfulGroupId] = {};
    }

    acc[contentfulGroupId][userId] = acc[contentfulGroupId][userId] || [];
    acc[contentfulGroupId][userId].push(group);
    
    return acc;
  }, {});

  return answerGroups;
};


const AnswerCard = ({ itemKey, heading, usersArray, students, classes, onClick, requiredGrading }) => {
  const toReviewCount = usersArray.length ? usersArray.filter(el => 
    Array.isArray(el) ? el[0].status === null : el.status === null
  ).length : 0;

  // when grading is not required for answer count all submitted answers as completed
  // when grading is required - answers with truthy status are completed
  const completedCount = requiredGrading && !!usersArray.length
                          ? usersArray.filter(el => Array.isArray(el) ? !!el[0].status : !!el.status).length
                          : usersArray.length || 0;


  const completedPercentage = completedCount > 0 ? Number(completedCount / students.length * 100).toFixed(0) : 0;


  return (
    <Grid key={itemKey} item xs={12} sm={12} md={12} lg={12} xl={12}>
      <Card
        className={classes.card}
        tabIndex="0"
        onClick={onClick}
      >
        <CardContent className={classes.cardContent}>
          <Grid container spacing={3}>
            <Grid className={classes.titleItem} item xs={12} md={6}>
              <Grid className={classes.titleWrapper} container>
                <Grid item>
                  {requiredGrading ? <GradeIcon className={clsx(classes.gradeIcon, classes.icon)} /> : <AssignmentOutlinedIcon className={classes.icon} />}
                </Grid>
                <Grid item>
                  <Typography className={classes.titleLabel}>
                    {heading}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={classes.contentItem} item xs={12} md>
              {requiredGrading
                ? <Typography>{`${toReviewCount} To Review`}
                  {!!toReviewCount
                  ? <ErrorIcon className={clsx(classes.notDoneIcon, classes.roundIcon)}/>
                    : <CheckCircleIcon className={clsx(classes.doneIcon, classes.roundIcon)}/>}
                  </Typography>
                : <Typography>Grading not required</Typography>}
            </Grid>
            <Grid className={classes.contentItem} item xs={12} md>
              <Typography>{`${completedPercentage} % Complete`}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

const Answers = ({
  user,
  curriculum,
  program,
  currentTab,
  setCurrentTab,
  showCertificateTab,
  showProjectTab,
  openTextAnswers: {
    programAnswers,
    programMembers,
    isLoadingCommentsByUser,
    isRemovingComment,
    isAddingComment,
    isUpdatingComment,
  },
  isLoading,
  getCommentsByUser,
  addComment,
  updateStatus,
  updateAnswerGroupsStatus,
  deleteComment,
  selectedGroupUsers,
  selectedUserId,
  setSelectedGroupUsers,
  usersGroupsList,
  originClasses,
  usersGroupFilter,
  answerGroups,
  addAnswerGroupComment,
  deleteAnswerGroupComment,
  editedAnswer,
  setEditedAnswer,
  updateComment,
  updateAnswerGroupComment,
  push,
}) => {
  const classes = useStyles();
  const history = useHistory();

  const [nodes, setNodes] = useState([]);
  const [rows, setRows] = useState({});
  const [students, setStudents] = useState({});
  const [selectedItem, setSelectedItem] = useState(null);
  const [activeGroups, setActiveGroups] = useState({});
  const [programQuestions, setProgramQuestions] = useState([]);
  const setSelectedAnswer = (item) => {
    if (!!item) {
      const answerId = !!item.isAnswerGroup ? item.answerId : item.question.contentSection.id;
      const clubId = program?.code;
      if (!!clubId) {
        push(`/progress/${clubId}/view/answers/${answerId}`);
      }
    }
    setSelectedItem(item);
  }

  useEffect(() => {
    if (!curriculum || !program || !curriculum[program.curriculum.code]) return;

    const currentCurriculum = curriculum[program.curriculum.code];

    const currentAnswerGroups = Object.values(answerGroups.answerGroups || {});
    const collections = helpers.getCurriculumCollections(currentCurriculum);

    setRows(programAnswers);
    setActiveGroups(formatGroups(currentAnswerGroups));
    const openTextQuestions = helpers.getOpenTextContentSections(currentCurriculum);
    setProgramQuestions(openTextQuestions);
    const collectionsWithAnswers = collections.map((collection) => {
      const answerGroupQuestions = {};
      const answerGroups = currentAnswerGroups.filter(([group]) => group.collection === collection.id);
      // remove answer groups from open text sections
      const openTextContentSections = helpers.getOpenTextContentSections(collection).map((section) => {
        const contentSectionGroup = section.contentSection.contentSectionGroup;
        if (!contentSectionGroup) return section;
        answerGroupQuestions[contentSectionGroup.id] = answerGroupQuestions[contentSectionGroup.id] || [];
        answerGroupQuestions[contentSectionGroup.id].push(section.contentSection);
        return null;
      }).filter(Boolean);
      const openTextAnswers = openTextContentSections.sort((a, b) => a.contentSection.heading - b.contentSection.heading);
      const openTextItems = openTextContentSections.map((section) => {
        const itemKey = `${section.activity.path}-${section.contentSection.id}`;

        const answers = programAnswers && programAnswers[itemKey];
        return answers;
      }).filter(Boolean);

      return {
        collection: collection.name,
        collectionId: collection.id,
        openTextAnswers,
        openTextItems,
        answerGroups,
        answerGroupQuestions,
      };
    });
  
    setNodes(collectionsWithAnswers);
  }, [curriculum, programMembers, programAnswers, answerGroups, editedAnswer]);

  useEffect(() => {
    if (!isLoading && !!programQuestions.length && editedAnswer && !!nodes.length) {
      const answerGroup = !!activeGroups && !!activeGroups[editedAnswer] && Object.values(activeGroups[editedAnswer]);
      if (!!answerGroup?.length) {
        const answersFromGroup = answerGroup[0];
        if (!!answersFromGroup.length) {
          const collection = nodes.find(node => node.collectionId === answersFromGroup[0].collection);
          const group = collection.answerGroupQuestions[answersFromGroup[0].contentfulGroupId].find((node) => node.contentSectionGroup.id === editedAnswer);
          setSelectedItem({ answerId: answersFromGroup[0].contentfulGroupId, heading: answersFromGroup[0].name, requiredGrading: group.requiredGrading, isAnswerGroup: true, answerGroupQuestions: collection.answerGroupQuestions });
        }
      }

      const currentNode = programQuestions.find((node) => node.contentSection.id === editedAnswer);
      const itemKey = currentNode && `${currentNode.activity.path}-${currentNode.contentSection.id}`;

      if (!!itemKey) {
        setSelectedItem({ answerId: itemKey, requiredGrading: currentNode.contentSection.requiredGrading, heading: currentNode.contentSection.heading, question: currentNode });
      }
      setEditedAnswer(null);
    };
  }, [editedAnswer, programQuestions, activeGroups])


  useEffect(() => {
    if (!program || !programAnswers || !programMembers.length)
      return;

    setRows(programAnswers);
    const programGroupMembers = programMembers.filter(user => usersGroupFilter(user, selectedGroupUsers));

    setStudents(programGroupMembers);
  }, [programAnswers, selectedGroupUsers]);

  return (
    <Box className={classes.contentBox}>
      <Box className={classes.header}>
        <PageTitle title="Progress" isLoading={isLoading} />
        { !!usersGroupsList.length && <FilterBlock
          classes={originClasses}
          setSelectedGroupUsers={(value) => setSelectedGroupUsers(value) }
          usersGroupsList={usersGroupsList}
          selectedGroupUsers={selectedGroupUsers}
        />}
      </Box>
      <div className={classes.tabsSection}>
        <Tabs
          value={currentTab}
          showCertificateTab={showCertificateTab}
          showProjectTab={showProjectTab}
          showAnswers={true}
          onChange={(evt, newValue) => {
            setCurrentTab(newValue);
            setSelectedItem(null);
          }}
        />
      </div>
      <Grid container>
        {selectedItem
          ? <>
            {
              <UserAnswers
                user={user}
                answers={selectedItem.isAnswerGroup ? activeGroups[selectedItem.answerId] : rows[selectedItem.answerId]}
                students={students}
                getCommentsByUser={getCommentsByUser}
                isLoadingCommentsByUser={isLoadingCommentsByUser}
                isRemovingComment={isRemovingComment}
                isAddingComment={isAddingComment}
                isUpdatingComment={isUpdatingComment}
                addComment={addComment}
                updateStatus={updateStatus}
                updateAnswerGroupsStatus={updateAnswerGroupsStatus}
                deleteComment={deleteComment}
                onBackClick={() => {
                  setSelectedAnswer(null);
                  const programCode = program?.code
                  programCode && push(`/progress/${programCode}/view/answers`);
                }}
                heading={selectedItem.heading}
                requiredGrading={selectedItem.requiredGrading}
                isAnswerGroup={selectedItem.isAnswerGroup}
                answerGroupQuestions={selectedItem.answerGroupQuestions}
                addAnswerGroupComment={addAnswerGroupComment}
                updateComment={updateComment}
                updateAnswerGroupComment={updateAnswerGroupComment}
                deleteAnswerGroupComment={deleteAnswerGroupComment}
                question={selectedItem.question}
                history={history}
                program={program}
                selectedItem={selectedItem}
                selectedUserId={selectedUserId}
                push={push}
              />
            }
          </>
          :
          <>
            {!isLoading && !!nodes.length && nodes.map(({collection, openTextAnswers, openTextItems, answerGroups, answerGroupQuestions}) => {
              if ((!openTextAnswers.length || !openTextItems.length) && !answerGroups.length) {
                return;
              }
              return <>
                <Typography component="h1" className={classes.collectionTitle}>
                  {collection}
                </Typography>
                {!!answerGroups.length &&
                    answerGroups.map((groups) => {
                      const group = groups[0];
                      const ansGroup = programQuestions.find(({activity}) => activity?.id === group.activity)
                      const requiredGrading = ansGroup.contentSection.requiredGrading;
                      const heading = group.name;
                      return (
                        <AnswerCard
                          key={group.id}
                          heading={group.name}
                          user={user}
                          classes={classes}
                          students={students}
                          onClick={() => {
                            setSelectedAnswer({ answerId: group.contentfulGroupId, heading, requiredGrading, isAnswerGroup: true, answerGroupQuestions });
                          }}
                          requiredGrading={requiredGrading}
                          usersArray={groups.flat().filter((item) =>  usersGroupFilter(item[0], selectedGroupUsers))}
                        />
                      );
                    })}
                {openTextAnswers.map((answer) => {
                  const itemKey = `${answer.activity.path}-${answer.contentSection.id}`;
                  const items = rows ? rows[itemKey] : {};

                  if (!items) {
                    return;
                  }

                  const usersArray = Object.values(items).filter(item => usersGroupFilter(item[0], selectedGroupUsers));
                  const requiredGrading = answer.contentSection.requiredGrading;
                  const heading = answer.contentSection.heading;

                  return <AnswerCard
                    key={itemKey}
                    heading={heading}
                    usersArray={usersArray}
                    students={students}
                    user={user}
                    classes={classes}
                    onClick={() => {
                      if (!!items && Object.values(items).length) {
                        setSelectedAnswer({ answerId: itemKey, requiredGrading, heading, question: answer });
                      }
                    }}
                    requiredGrading={requiredGrading}
                  />;
                })}
              </>
            })}
          </>}
      </Grid>
    </Box>
  );
};

export default Answers;
