import React from "react";
import { Link } from "react-scroll";

export default class MenuItem extends React.PureComponent {
  render() {
    const { id, title, subContent, offset, onClick } = this.props;
    const isSubContent = !!subContent ? "nav-item-sub" : "";
    return (
      <React.Fragment>
        <Link
          activeClass="active"
          to={id}
          spy={true}
          offset={offset}
          className={`nav-item ${isSubContent}`}
          onClick={onClick}
        >
          {title}
        </Link>
      </React.Fragment>
    );
  }
}
