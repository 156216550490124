import { gql } from "apollo-boost";

import client from "../../../apolloClient";

export const GetUserInvitationToken = invitationToken => {
  return client.query({
    query: gql`
  {
    getUserNextInvitationByToken(token:"${invitationToken}"){
      id
      programId
      partnershipId
      pending
      invitationToken
      invitedFirstName
      email
      isUserExist
      facilitatorInvitation
      program {
        id
        code
        programType
        programTypeId
        active
        completed
      }
    }
  }
  `
  });
};
