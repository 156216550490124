import { call, put } from "redux-saga/effects";
import { push } from "connected-react-router";
import qs from "query-string";

import  { receivedStaticPagesContent, receivedStaticPagesNav, receivedStaticPageByUrl } from '../actions/global';
import { showErrorMessage } from '../actions/notification';
import { addUserActivitiesInfoItem } from '../actions/userActivitiesInfo';

import * as userActivityLogs from '../api/userActivitiesInfo';
import * as staticPagesQueries from '../api/graphql/query/staticPages.queries';

const roleRoutes = [
  { path: "attendance", roles: [1, 3] },
  { path: "messages", roles: [1, 2, 3] },
  { path: "calendar" },
  { path: "gwc", roles: [1, 2, 6] },
  { path: "join-program", roles: [] },
  { path: "community-partnership", roles: [6] },
  { path: "community-partner-dashboard-faq", roles: [6] },
  { path: "international-community-partner-dashboard-faq", roles: [6] },
  { path: "user-profile", roles: [] },
  { path: "profile", roles: [] },
  { path: "getting-started" },
  { path: "cookie-policy" },
  { path: "static-pages" },
  { path: "terms" },
  { path: "privacy" },
  { path: "code-of-conduct" },
  { path: "project-gallery" },
  { path: "forgot-password" },
  { path: "change-password" },
  { path: "post" },
  { path: "join-club" },
  { path: "join-loop" },
  { path: "profile" },
  { path: "user-profile" },
  { path: "club-profile" },
  { path: "home" },
  { path: "gwc-club-application" },
  { path: 'new-project' },
  { path: "verify" },
  { path: "start-club-application" },
  { path: "gwc-challenges" },
];

export function* roleChanged({ payload }) {
  const { roleId, redirectTo } = payload;
  const { pathname, search } = window.location;
  const path = pathname
    .substring(1, pathname.length)
    .split("/")[0];

  const rr = roleRoutes.filter(
    r =>
      r.path === path &&
      (!r.roles || r.roles.indexOf(roleId) !== -1 || r.roles.length === 0)
  );

  if (rr.length > 0) {
    return;
  }

  const invite = pathname.includes('invitation');
  const facilitatorInvitation = pathname.includes('facilitatorInvitation');

  const { invitationToken, redirect, facilitatorInvitationToken } = qs.parse(search);

  if (facilitatorInvitation || facilitatorInvitationToken) {
    return yield put(push('/gwc-club-application?facilitate=true'))
  }

  if (invite || invitationToken) {
    const tokenId = invitationToken ? invitationToken
    : pathname
    .substring(1, pathname.length)
    .split("/")[1];

    return yield put(push(`/join-club/${tokenId}`));
  }

  if (redirect) {
    return yield put(push(`${redirect}`));
  }

  if (!path || (path === 'login' && !search)) {
    // Redirect to home page if path is empty
    if (redirectTo) {
      return yield put(push(redirectTo));
    }
    return yield put(push("/home"));
  }

  const dontRedirectFromPaths = ['members', 'progress', 'renew-club'];

  if (!path || dontRedirectFromPaths.includes(path)) return;

  switch (roleId) {
    case 1:
      yield put(push("/gwc"));
      break;
    case 2:
      yield put(push("/gwc"));
      break;
    case 4:
      yield put(push("/gwc"));
      break;
    case 6:
      yield put(push("/community-partnership"));
      break;
    case 10:
      yield put(push("/gwc"));
      break;
    default:
      break;
  }

  // yield put(push('/announcements'));

  /* if (window.location.pathname.indexOf('/announcements') === 0 || window.location.pathname.indexOf('/join-club') === 0) return;

  switch(payload) {
    case 1:
      if (window.location.pathname.indexOf('/gwc') === 0) break;
      yield put(push('/gwc'));
      break;
    case 6:
      yield put(push('/community-partnership'));
      break;
    default:
      yield put(push('/gwc'));
      break;
  } */
}

export function* setActivityLog({ payload }) {
  try {
    let { data } = yield call(userActivityLogs.createUserActivity, payload);

    yield put(addUserActivitiesInfoItem(data));
  } catch (e) {
    // TODO: throw generic error
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* getStaticPages({ payload }) {
  try {
    let { data: { PageQuery } } = yield call(staticPagesQueries.getStaticPages, payload);

    yield put(receivedStaticPagesContent(PageQuery))
  }
  catch (e) {
    console.log('TODO: handle error here');
    console.log(e);
  }
}

export function* getStaticPagesNav() {
  try {
    let { data: { GetAllStaticPages } } = yield call(staticPagesQueries.getAllStaticPages);

    yield put(receivedStaticPagesNav(GetAllStaticPages))
  }
  catch (e) {
    yield put(receivedStaticPagesNav(null));
    yield put(showErrorMessage("Failed to pull static pages navigation."));
    console.log(e);
  }
}

export function* getStaticPageByUrl({ payload: { url } }) {
  try {
    let { data: { GetStaticPageByUrl } } = yield call(staticPagesQueries.getStaticPageByUrl, { url });

    yield put(receivedStaticPageByUrl(GetStaticPageByUrl))
  }
  catch (e) {
    yield put(receivedStaticPagesNav(null));
    yield put(showErrorMessage("Failed to pull static page data."));
    console.log(e);
  }
}