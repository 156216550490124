import { createAction } from 'redux-act';

export const setMenuVisibility = createAction();
export const incrementInitialLoaders = createAction();
export const decrementInitialLoaders = createAction();

export const roleChanged = createAction();

export const setMenuMode = createAction();
export const setIsMobile = createAction();

export const setActivityLog = createAction();

export const setDefaultState = createAction();

export const getStaticPages = createAction();
export const receivedStaticPagesContent = createAction();

export const getStaticPagesNav = createAction();
export const receivedStaticPagesNav = createAction();

export const getStaticPageByUrl = createAction();
export const receivedStaticPageByUrl = createAction();

export const setStudentView = createAction();